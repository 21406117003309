.addStaff {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 75%;
    .titleContent {
        font-size: 25px;
        padding-top: 4px;
        padding-left: 5px;
    }

    // .goBack {
    //     position: absolute;
    //     border-radius: 50%;
    //     font-size: 22px;
    //     border: unset;
    //     padding: 12px 15px;
    //     transition: 0.3s ease-in-out;
    //     background-color: #FFF;
    //     color: #FBD74D;
    //     border-radius: 50% !important;
    //     border: 4px #FBD74D solid;
    //     right: 5px;
    //     top: 18.7%;
    //     cursor: pointer;

    //     &:hover {
    //         background-color: #FBD74D;
    //         border: 5px #FFF solid;
    //         color: #FFF;
    //     }
    // }

    .addStaffStatic {
        position: fixed;
        top: 18%;
        bottom: 0;
        right: 0px;
        height: auto;
        overflow: auto;
        margin: 0 auto;

        &::-webkit-scrollbar {
            width: 0;
        }


        .addStafForm {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0px 0px 12px 12px;
            margin: 0 auto;

            .buttonatAddStaf {
                .buttonNdrysho {
                    background-color: #FBD74D;
                    border: none;
                    border-radius: 5px;
                    padding: 5px;
                    color: white;
                    font-size: 16px;
                }

                .profile-picture-add-Staf {
                    margin: 15px 0px;
                    background-color: #FBD74D;
                    border: none;
                    border-radius: 5px;
                    padding: 6px 10px 6px 10px;
                    color: white;
                    font-size: 20px;
                    // text-transform: uppercase;
                    cursor: pointer;
                    width: 34%;

                    p {
                        padding-top: 5px;
                        font-size: 15px;
                    }

                    input {
                        display: none;
                    }
                }
            }

            .profile-image-box-Add-Staff {
                img {
                    object-fit: cover;
                    width: 132px;
                    height: 132px;
                    border-radius: 50%;
                    margin-top: 9px;
                }
            }

            .logoja {
                width: 70px;
            }

            form {
                width: 50%;
                display: block;
                margin-top: 35px;
                font-family: Lato;
                margin-bottom: 50px;

                .form-input {

                    .daysDiv {
                        display: flex;
                        border-radius: 5px;
                        margin: 0 auto;

                        .daysLabel {
                            margin: 0 auto;
                        }

                        .days {
                            width: 14.2%;
                            display: flex;
                            padding: 10px 0px;
                            flex-direction: column;
                            border: 2px #FBD74D solid;

                            &:not(:last-child) {

                                border-right: none;
                            }
                        }
                    }

                    .input-design {

                        .button_forgot {
                            width: 100%;
                            border-radius: 5px;
                            border: 2px #FBD74D solid;
                            padding: 10px;
                            transition: .3s ease-in-out;
                            background-color: #FBD74D;
                            color: white;
                            font-weight: 600;
                            width: 37%;

                        }

                        .inputt {
                            background: transparent;
                            height: 35px;
                            color: black;
                            border: 2px #FBD74D solid;
                            border-radius: 5px;
                            width: 50px;
                            padding: 10px 3px !important;
                            margin-bottom: 20px;
                            // margin-left: 1.4%;
                        }

                        .roletAddSelect {
                            border-radius: 5px;
                            border: 2px #FBD74D solid;
                        }

                        @supports(-webkit-appearance: none) or (-moz-appearance: none) {
                            input[type='checkbox'] {
                                --active: #FBD74D;
                                --active-inner: #fff;
                                --focus: 2px rgba(39, 94, 254, .3);
                                --border: #BBC1E1;
                                --border-hover: #FBD74D;
                                --background: #fff;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                height: 21px;
                                outline: none;
                                display: inline-block;
                                vertical-align: top;
                                position: relative;
                                margin: 0;
                                cursor: pointer;
                                border: 1px solid var(--bc, var(--border));
                                background: var(--b, var(--background));
                                transition: background .3s, border-color .3s, box-shadow .2s;
                                padding: 8px 18px;

                                &:after {
                                    content: '';
                                    display: block;
                                    left: 0;
                                    top: 0;
                                    position: absolute;
                                    transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
                                }

                                &:checked {
                                    --b: var(--active);
                                    --bc: var(--active);
                                    --d-o: .3s;
                                    --d-t: .6s;
                                    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
                                }

                                &:hover {
                                    &:not(:checked) {
                                        &:not(:disabled) {
                                            --bc: var(--border-hover);
                                        }
                                    }
                                }

                                &:focus {
                                    box-shadow: 0 0 0 var(--focus);
                                }

                                &:not(.switch) {
                                    width: 21px;

                                    &:after {
                                        opacity: var(--o, 0);
                                    }

                                    &:checked {
                                        --o: 1;
                                    }
                                }

                            }

                            input[type='checkbox'] {
                                &:not(.switch) {
                                    border-radius: 7px;

                                    &:after {
                                        width: 5px;
                                        height: 9px;
                                        border: 2px solid var(--active-inner);
                                        border-top: 0;
                                        border-left: 0;
                                        left: 7px;
                                        top: 4px;
                                        transform: rotate(var(--r, 20deg));
                                    }

                                    &:checked {
                                        --r: 43deg;
                                    }
                                }

                                &.switch {
                                    width: 43px;
                                    border-radius: 11px;

                                    &:after {
                                        left: 2px;
                                        top: 2px;
                                        border-radius: 50%;
                                        width: 15px;
                                        height: 15px;
                                        background: var(--ab, var(--border));
                                        transform: translateX(var(--x, 0));
                                    }

                                    &:checked {
                                        --ab: var(--active-inner);
                                        --x: 17px;
                                    }

                                    &:disabled {
                                        &:not(:checked) {
                                            &:after {
                                                opacity: .6;
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }


                }


                .bio {

                    min-height: 100px;

                }

                .form-control {

                    background: transparent;
                    height: 50px;
                    color: black;
                    border: 2px #FBD74D solid;
                    border-radius: 5px;
                    padding-left: 20px;
                    padding-right: 20px;


                    &::-webkit-input-placeholder {
                        color: black;
                    }

                    &:hover,
                    &:focus {
                        background: transparent;
                        outline: none;
                        box-shadow: none;
                        border-color: rgb(238, 198, 124);
                    }


                }




                ::placeholder {
                    color: #cbcbcb;
                    font-size: 17px;
                }

                label {
                    width: 100%;

                    select {
                        width: 100%;
                        height: 70px;
                        position: relative;
                        margin: 10px;
                        border: 1px solid #e5e5e5;
                        border-radius: 10px;
                        background-color: transparent;
                        padding: 10px 20px;
                        margin: 12px 0;
                        font-size: 1rem;
                        color: #696969;

                        &:hover,
                        &:focus {
                            outline: none;
                        }
                    }
                }

                .title {
                    position: fixed;
                    color: #5a5a5a;
                    font-size: 20px;
                }

                .register-button {
                    width: 100%;
                    height: 70px;
                    margin: 10px 0;
                    background-color: #fdcd70;
                    border: none;
                    border-radius: 10px;
                    padding: 12px;
                    // font-weight: bold;
                    color: white;

                    font-size: 20px;
                }

                a {
                    text-align: center;
                }



                span {
                    hr {
                        width: 70%;
                    }
                }

                .btn {
                    margin: 20px 0px 12px 0px;
                    // cursor: pointer;
                    box-shadow: none !important;
                    font-size: 15px;
                    text-transform: uppercase;

                    &.btnLogin {
                        width: 100%;
                        background-color: #FBD74D;
                        transition: .3s ease-in-out;
                        color: black;
                        font-weight: 600;
                        border-radius: 5px;
                        margin-bottom: 10px;
                        border: 2px #FBD74D solid;
                    }
                }
            }

        }


    }
}

@media screen and (max-width: 900px) {
    .addStaff {
        overflow: hidden;

        .addStaffStatic {
            .addStafForm {
                form {
                    width: 100% !important;

                    .check_box {
                        .radio-wrapper {
                            flex-direction: column;
                            align-items: baseline;
                        }
                    }

                    .btnOrari {
                        width: 47%;
                    }
                }

                .checkbox_label {
                    line-height: unset;
                }
            }
        }
    }

}

@media screen and (max-width: 440px) {
    .addStaff {
        .addStaffStatic {
            width: 100% !important;


            .addStafForm {
                form {
                    .form-input {
                        .btnOrari {
                            width: 47%;
                        }
                    }

                    input {

                        padding: 10px 30px;
                        margin: 9px 0;

                    }

                    .check_box {
                        label#kushtet {
                            margin-bottom: 5px;
                            margin-left: 2px;
                            padding-right: 1px;
                        }
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 700px) {
    .addStaff {
        .addStaffStatic {
            .addStafForm {
                width: 82%;

                form {
                    .form-input {
                        .daysDiv {
                            display: flex;
                            flex-flow: row wrap;
                            border: 1px #FBD74D solid;

                            .daysLabel {
                                margin: 0 auto;
                            }

                            .days {
                                width: 50%;
                                border-right: 1px #FBD74D solid;
                                border: 1px #FBD74D solid;
                            }

                            .days:last-child {
                                width: 100%;
                                margin: 0px;
                            }

                            .days:not(:last-child) {
                                border-right: 1px #FBD74D solid;
                            }
                        }
                    }
                }

                .input-design {
                    .teksti {
                        width: 95%;
                    }

                }

            }
        }
    }

}

/* Chrome, Safari, Edge, Opera */
.xona20::-webkit-outer-spin-button,
.xona20::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.emailiHover {
    &:hover {
        cursor: not-allowed;
    }
}