.terminet {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 75%;

    .terminetNav {
        text-align: left;
        // margin-top: 135px;
        margin-top: 0px;
        padding-left: 20px;

        button {
            border-radius: 10px;
            margin-right: 10px;
            padding: 5px 10px;
            border: unset;

            &:hover {
                background-color: #FBD74D;
                color: white;
            }
        }

        .active {
            background-color: #FBD74D;
            color: black;
        }
    }

    .terminetPosition {
        position: fixed;
        top: 26%;
        bottom: 0;
        right: 0px;
        height: auto;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 0;
        }

        .terminetLista {
            margin: 0 auto;
            width: 98%;



            .shfaqjet-listimi {
                padding: 27px 40px;
                margin: 0 auto;


                .data {
                    border: 5px solid #FBD74D;
                    border-radius: 20px 20px 0 20px;

                    .border-bottom {
                        border-bottom: 2px solid #FBD74D !important;
                    }
                }

                .infot {
                    border-bottom: 5px solid #FBD74D;
                    padding-left: 40px;

                    .viti {
                        position: absolute;
                        right: -15px;
                        bottom: -50px;
                        background-color: #FBD74D;
                        padding: 5px 10px;
                        border-radius: 0px 0px 20px 20px;
                        margin-right: 15px;
                        box-shadow: 0px 7px 44px rgba(251, 252, 253, 0.25), 0px 7px 44px rgba(255, 255, 255, 0.13);
                    }

                    .anulo {
                        position: absolute;
                        bottom: -50px;
                        right: 47px;
                        background-color: #FBD74D;
                        box-shadow: 0px 7px 44px rgb(251 252 253 / 25%), 0px 7px 44px rgb(255 255 255 / 13%);
                        border-radius: 0px 0px 20px 20px;

                        .btnAnulo {
                            color: white;

                            &:focus {
                                outline: none;
                                border: none;
                            }

                            &:active {
                                outline: none;
                            }
                        }
                    }





                    .ora {
                        background-color: #FBD74D;
                        padding: 5px 10px;
                        border-radius: 0px 20px 20px 20px;
                        margin-right: 15px;
                        height: 40px;
                    }

                    .shfaqjet-titulli {
                        color: #FBD74D;
                        text-align: left;
                        max-width: 71%;
                        padding: 5px 10px;
                    }

                    h4 {
                        text-transform: uppercase;
                        font-weight: 500;
                    }
                }
            }

            .aktori {
                width: auto;
                text-transform: capitalize;
                background-color: #FBD74D;
                margin-left: 10px;
                border-radius: 20px;
                padding: 5px 15px;
                font-size: 15px;
            }

            .aktorja {
                text-transform: capitalize;
                margin-left: 10px;
                border-radius: 20px;
                padding: 5px 15px;
                font-size: 18px;
                padding-left: 0px !important;
            }


        }
    }
}





@media screen and (max-width: 760px) {
    .terminet {
        margin-top: 20px;

        .terminetPosition {
            &::-webkit-scrollbar {
                width: 0 !important;
            }

            .terminetLista {
                .shfaqjet-listimi {
                    width: 93% !important;
                    padding: 38px;
                    margin-top: 10px;

                    .infot {
                        border-bottom: 5px solid #FBD74D;
                        border-left: 5px solid #FBD74D;
                        border-right: 5px solid #FBD74D;
                        border-radius: 0px 0px 12px 12px;
                    }

                    .data {
                        border: 5px solid #FBD74D;
                        border-radius: 12px 12px 0px 0px;
                    }

                    .aktori {
                        padding: 1px 7px;
                    }
                }
            }
        }


        .infotData {
            display: block !important;
        }

    }
}

// @media screen and (max-width: 800px) {
//     .terminet {
//         .terminetNav {
//             margin-top: 20px;
//         }

//         // .terminetPosition {
//         //     top: 15%;
//         // }
//     }
// }