.stafiKompaniseContent {
    .stafiKompanise {
        position: absolute;
        top: 10%;
        left: 1%;
        right: 2.5%;
        .stafiPosition:not(.ourTeam) {
            position: fixed;
            top: 18%;
            bottom: 0;
            right: 0px;
            height: auto;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 0;
            }
            .ourTeam {
                .memberListing {
                    width: 26%;
                    cursor: pointer;
                    margin-bottom: 97px;
                    .memberImage {
                        border-radius: 20px;
                        filter: drop-shadow(2px 4px 4px lightgrey);
                        background: white;
                        overflow: hidden;
                        img {
                            width: 100%;
                            object-fit: cover;
                            border-radius: unset;
                            max-height: 300px;
                            min-height: 300px;
                        }
                    }
                    .memberContent {
                        height: auto;
                        border-radius: 0 0 20px 20px;
                        padding: 3px;
                        background: border-box;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 375px) {
    .stafiKompaniseContent {
        .stafiKompanise {
            .stafiPosition:not(.ourTeam) {
                position: fixed;
                top: 33%;
                bottom: 0;
                right: 0px;
                height: auto;
                overflow: auto;
                &::-webkit-scrollbar {
                    width: 0;
                }
                .ourTeam {
                    .memberListing {
                        width: 26%;
                        cursor: pointer;
                        margin-bottom: 97px;
                        .memberImage {
                            border-radius: 20px;
                            filter: drop-shadow(2px 4px 4px lightgrey);
                            background: white;
                            overflow: hidden;
                            img {
                                width: 100%;
                                object-fit: cover;
                                border-radius: unset;
                                max-height: 300px;
                                min-height: 300px;
                            }
                        }
                        .memberContent {
                            height: auto;
                            border-radius: 0 0 20px 20px;
                            padding: 3px;
                            background: border-box;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    .ourTeam {
        margin-left: 11px;
    }
}