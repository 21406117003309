.kompanite {
    position: absolute;
    top: 10%;
    left: 1%;
    right: 2.5%;
    .kompatniteContainer {
        position: fixed;
        top: 19%;
        bottom: 0;
        right: 0px;
        height: auto;
        overflow: auto;
         .product-img {
            width: 150px;
            border-radius: 50%;
            border: 10px gray solid;
         }
          .products {
             position: relative;
             left: 0;
             right: 0;
             width: 100%;
             margin: 0 auto;
               .productsRow {
                   margin: 0px !important;
               }
        }
        @media only screen and (max-width: 600px) {
            width: 100%;
        }
          .productListing {
              @media only screen and (max-width: 600px) {
                  width: 100%;
                  margin: 20px; 
              }
              width: 30%;
              background-color: #f1f1f1;
              margin: 0 auto;
              margin-bottom: 30px;
              border-radius: 20px;
              overflow: hidden;
              transition: ease-in-out .3s;
              height: 300px;
              filter: drop-shadow(2px 4px 4px lightgrey);
              overflow: hidden !important;
              cursor: pointer;
              background-position: center;
            .productContent {
                transition: ease-in-out .3s;
                text-align: left;
                width: 100%;
                 .productImage {
                     width: 50%;
                 }
                   .productName {
                       margin: 10px;
                       margin-bottom: 20px;
                       font-size: 25px;
                       text-transform: uppercase;
                       color: #FBD74D;
                       transition: ease-in-out .3s;
                       padding-left: 8px;
                       padding-top: 8px;
                       word-break: break-word;
                       width: 50%;
                   }
                .kompaniteDisplay {
                    display: none;
                    .productCapacity {
                        text-align: left;
                        padding: 5px;
                        padding-left: 8px;
                        a {
                            color: black;
                            text-decoration: none;
                        }
                    }
                    .productCapacityValue {
                        max-width: 300px;
                        padding: 5px;
                        padding-left: 8px;
                        background: #FBD74D;
                        border-radius: 0 10px 10px 0;
                        color: white;
                        font-weight: 600;
                    }
                }
            }
            @media only screen and (min-width: 750px)  {
            &:hover,
            &:focus {
                background-image: none !important;
                overflow: hidden !important;
                .productName {
                    margin: 20px;
                    width: 30%;
                }
                .productUpdate {
                    position: absolute;
                    text-align: right;
                    right: 43%;
                    bottom: 0;
                    padding: 5px 20px;
                    background: #FBD74D;
                    color: white;
                    border-radius: 10px 10px 0 0;
                    filter: drop-shadow(0 0 0.75rem gray);
                }
                .productPrice {
                    position: absolute;
                    width: 57%;
                    text-align: right;
                    color: white;
                    background-color: red;
                    font-size: 22px;
                    overflow: none;
                    padding: 5px;
                    padding-right: 10px;
                    span {
                        filter: drop-shadow(0 0 1rem gray);
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        top: 0;
                        width: 60%;
                        text-align: right;
                        display: inline;
                        padding: 10px;
                        background: #FBD74D;
                        font-size: 16px;
                    }
                }
                .productImage {
                    width: 0% !important;
                    float: left;
                    text-align: left;
                    overflow: hidden !important;
                    img {
                        width: 100px;
                    }
                }
                .kompaniteDisplay {
                    display: block;
                    width: 70%;
                    margin: 20px;
                    .productName {
                        margin: 10px;
                        margin-bottom: 20px;
                        font-size: 25px;
                        text-transform: uppercase;
                        color: #FBD74D;
                        transition: ease-in-out .3s;
                        padding-left: 8px;
                        padding-top: 8px;
                        word-break: break-word;
                        width: 50%;
                    }
                    .productContent {
                        border-left: 15px solid white;
                        width: 100%;
                        z-index: 999999;
                        padding: 20px;
                        .productCapacityValue {
                            background-color: white;
                            color: #FBD74D;
                            filter: drop-shadow(0 0 0.75rem gray);
                            overflow: hidden !important;
                            margin-left: -15px;
                            padding-left: 20px;
                        }
                        .productCapacity {
                            overflow: hidden !important;
                            color: white;
                            a {
                                color: white;
                                text-decoration: none;
                           }
                        }
                    }
                }
            }
        }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .fromTo {
        left: 55%;
        .refresh {
            height: 36px;
            width: 36px;
            margin: 0px;
        }
    }
}
@media only screen and (max-width: 750px)  {
    .productListing {
       
        .productName{
            color: #f1f1f1 !important;
            font-weight: 600;

        }
        .kompaniteDisplay{
            display: block !important;
            width: 100%;
            background-color: rgba(0,0,0,0.25);
            height: 100%;
            top: 0px;
            position: absolute;
            border-radius: 20px;
            .productCapacity {
                color: white;
                font-weight: 600;
                a {
                    color: white!important;
                    font-weight: 600;
                    text-decoration: none;
                }
            }
            .productCapacityValue {
               background-color: none;
            }
        }
     
    }
}
@media only screen and (max-width: 376px) {
    .fromTo {
        .refresh {
            height: 36px;
            width: 36px;
            margin: 0px;
        }
    }
}