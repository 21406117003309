.resetPassword {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    form {
        display: block;
        margin-top: 35px;
        font-family: Lato;
        margin-bottom: 50px;
        margin: 14px;
  
        .form-input {

            .form-control {
                width: 100%;
                border-radius: 5px;
                margin-bottom: 10px;
                border: 2px #FBD74D solid;
                padding: 10px;


                &::-webkit-input-placeholder {
                    color: black;
                }

                &:hover,
                &:focus {
                    background: transparent;
                    outline: none;
                    box-shadow: none;
                    border-color: rgb(238, 198, 124);
                }
            }
        }
    
        
        .button_forgot {
            width: 100%;
            border-radius: 5px;
            border: 2px #FBD74D solid;
            padding: 10px;
            transition: .3s ease-in-out;
            background-color: #FBD74D;
            color: white;
            font-weight: 600;
   
            &:hover {
                width: 100%;
                color: white;
            }
        }

    }
}