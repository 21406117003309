.EditProfileDoktorat {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 75%;

    .btnActive {
        display: flex;
        justify-content: center;
        border-radius: 0px 0px 12px 12px;
        margin-top: 82px;
        margin-bottom: 20px;
        // margin-right: 164px;

        button {
            border-radius: 10px;
            margin: 0px 10px;
            padding: 5px 10px;
            border: unset;
            width: 25%;

            &:hover {
                background-color: #FBD74D;
                // color: white;
            }
        }

        .active {
            background-color: #FBD74D;
            color: black;
            font-size: 15px;
        }
    }

    .titleGoBack {
        position: fixed;
        z-index: 99999;
        cursor: pointer;
        font-size: 46px;

    }

    .titleContent {
        font-size: 25px;
        padding-top: 4px;
        padding-left: 5px;

    }


    // .goBack {
    //     position: absolute;
    //     border-radius: 50%;
    //     font-size: 22px;
    //     border: unset;
    //     padding: 12px 15px;
    //     transition: 0.3s ease-in-out;
    //     background-color: #FFF;
    //     color: #FBD74D;
    //     border-radius: 50% !important;
    //     border: 4px #FBD74D solid;
    //     right: 5px;
    //     top: 18.7%;
    //     cursor: pointer;

    //     &:hover {
    //         background-color: #FBD74D;
    //         border: 5px #FFF solid;
    //         color: #FFF;
    //     }
    // }

    .EditModalStatic {
        position: fixed;
        top: 18%;
        bottom: 0;
        right: 0px;
        height: auto;
        overflow: auto;
        margin: 0 auto;

        .editPass {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0px 0px 12px 12px;
            // margin-right: 164px;

            // background-color: #fff;
            // padding-bottom: 100px;

            .logoja {
                width: 70px;
            }

            form {
                width: 50%;
                display: block;
                margin-top: 35px;
                font-family: Lato;
                margin-bottom: 50px;

                .form-control2 {
                    background: transparent;
                    height: 50px;
                    color: black;
                    border: 2px #FBD74D solid;
                    border-radius: 5px;
                    padding-left: 20px;
                    padding-right: 20px;

                    &::-webkit-input-placeholder {
                        color: black;
                    }

                    &:hover,
                    &:focus {
                        background: transparent;
                        outline: none;
                        box-shadow: none;
                        border-color: rgb(238, 198, 124);
                    }
                }

                .field-icon {
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    color: black;
                    cursor: pointer;
                }

                .form-group {
                    position: relative;
                }

                .check_box {
                    width: 100%;
                    padding-left: 5px;
                    // height: 70px;

                    .radio-wrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    p {
                        margin-bottom: 0;
                        font-size: 1rem;
                    }

                    input {
                        width: 20px;
                        height: 20px;
                        accent-color: darkgoldenrod;

                    }

                    label {

                        width: auto;
                        margin-bottom: 10px;
                        margin-left: 5px;
                        font-size: px;
                        padding-right: 22px;
                        color: #696969;
                        vertical-align: middle;


                    }
                }

                input {
                    width: 100%;
                    position: relative;
                    border: 1px solid #e5e5e5;
                    border-radius: 10px;
                    background-color: transparent;
                    padding: 10px 30px;
                    margin: 14px 0;
                    height: 70px;

                    &:hover,
                    &:focus {
                        outline: none;
                    }

                    &::-webkit-input-placeholder {
                        color: grey;
                        font-size: 15px;
                    }


                }

                .PhoneInput {
                    height: 80px;
                }

                ::placeholder {
                    color: #cbcbcb;
                    font-size: 17px;
                }

                label {
                    width: 100%;

                    span {
                        font-size: 18px;
                    }

                    select {
                        width: 100%;
                        height: 70px;
                        position: relative;
                        margin: 10px;
                        border: 1px solid #e5e5e5;
                        border-radius: 10px;
                        background-color: transparent;
                        padding: 10px 20px;
                        margin: 12px 0;
                        font-size: 1rem;
                        color: #696969;

                        &:hover,
                        &:focus {
                            outline: none;
                        }
                    }


                }

                .title {
                    position: fixed;
                    color: #5a5a5a;
                    font-size: 20px;
                }

                .register-button {
                    width: 100%;
                    height: 70px;
                    margin: 10px 0;
                    background-color: #fdcd70;
                    border: none;
                    border-radius: 10px;
                    padding: 12px;
                    // font-weight: bold;
                    color: white;

                    font-size: 20px;
                }

                a {
                    text-align: center;
                }

                .forgot_pass p {
                    margin-top: 15px !important;
                }

                span {
                    hr {
                        width: 70%;
                    }
                }

                .btn {
                    margin: 20px 0px 12px 0px;
                    // cursor: pointer;
                    box-shadow: none !important;
                    font-size: 15px;
                    text-transform: uppercase;

                    &.btnLogin {
                        width: 100%;
                        background-color: #FBD74D;
                        transition: .3s ease-in-out;
                        color: black;
                        font-weight: 600;
                        border-radius: 5px;
                        margin-bottom: 10px;
                        border: 2px #FBD74D solid;
                    }
                }
            }

            .tacbox {
                display: flex;
                padding: 1em;
                vertical-align: middle;
                max-width: 800px;
                align-items: center;

                label {
                    padding-top: 5px;
                    padding-left: 10px;
                    font-size: 14px;
                }

                input {
                    height: 2em;
                    width: 2em;
                    vertical-align: middle;
                    accent-color: darkgoldenrod;
                }
            }
        }

        &::-webkit-scrollbar {
            width: 0;
        }

        .editForm {
            display: block;
            justify-content: center;
            align-items: center;
            border-radius: 0px 0px 12px 12px;
            margin-top: 52px;
            margin-right: 164px;
            width: 56%;
            margin: 0 auto;

            form {
                .bio {
                    .textareaDesign {
                        min-height: 100px;
                    }
                }

                .inputt {
                    background: transparent;
                    height: 35px;
                    color: black;
                    border: 2px #FBD74D solid;
                    border-radius: 5px;
                    padding: 10px 3px !important;
                    margin-top: 0px;
                    margin-bottom: 20px;
                    // margin-left: 1.4%;
                    text-align: center;
                }

                .roletSelect {
                    border-radius: 5px;
                    border: 2px #FBD74D solid;
                }

            }

            .linkOrari {
                text-decoration: none !important;
                color: black !important;
                font-size: 15px;
                margin: 15px 0px;
                float: right;
                background-color: #FBD74D;
                border: none;
                border-radius: 5px;
                padding: 6px 10px 6px 10px;
                padding: 6px;
                cursor: pointer;
                width: 30%;
            }


            .profile-picture-editBtn {
                .buttonNdrysho {
                    font-size: 17px;
                }

                margin: 0 auto;
                background-color: #FBD74D;
                border: none;
                border-radius: 5px;
                padding: 6px 10px 6px 10px;
                font-size: 22px;
                cursor: pointer;
                display: block;
                width: 50%;
                margin: 0 auto;

                input {
                    display: none;
                }
            }

            .changePicBtn {
                margin: 5px 0;
                background-color: #FBD74D;
                border: none;
                border-radius: 5px;
                padding: 8px 35px;
                color: white;
                font-size: 20px;
                margin: 3px 0px !important;
            }



            .not-allowed {
                cursor: not-allowed;
            }

            .form-control {
                background: transparent;
                height: 50px;
                color: black;
                border: 2px #FBD74D solid;
                border-radius: 5px;
                padding-left: 20px;
                padding-right: 20px;



                &::-webkit-input-placeholder {
                    color: grey;
                }

                &:hover,
                &:focus {
                    background: transparent;
                    outline: none;
                    box-shadow: none;
                    border-color: rgb(238, 198, 124);
                }

            }

            .btn {
                margin: 20px 0px 12px 0px;
                box-shadow: none !important;
                font-size: 15px;
                text-transform: uppercase;

                &.btnRuaj {
                    width: 100%;
                    background-color: #FBD74D;
                    transition: .3s ease-in-out;
                    color: black;
                    font-weight: 600;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    border: 2px #FBD74D solid;
                }
            }

            .profile-image-box-V {
                img {
                    object-fit: cover;
                    width: 180px;
                    height: 180px;
                    border-radius: 50%;
                    margin-top: 9px;
                }
            }

            .logoja {
                width: 70px;
            }

            .input-design {
                .teksti {
                    width: 95%;
                }



                .text {
                    width: 14.2%;
                    text-align: center;
                }

                .checkboxForm {
                    width: 14.2%;
                    text-align: center;
                }

                label {
                    p {
                        color: #7e8092;
                    }
                }

                @supports(-webkit-appearance: none) or (-moz-appearance: none) {
                    input[type='checkbox'] {
                        --active: #FBD74D;
                        --active-inner: #fff;
                        --focus: 2px rgba(39, 94, 254, .3);
                        --border: #BBC1E1;
                        --border-hover: #FBD74D;
                        --background: #fff;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        height: 21px;
                        outline: none;
                        display: inline-block;
                        vertical-align: top;
                        position: relative;
                        margin: 0;
                        cursor: pointer;
                        border: 1px solid var(--bc, var(--border));
                        background: var(--b, var(--background));
                        transition: background .3s, border-color .3s, box-shadow .2s;

                        &:after {
                            content: '';
                            display: block;
                            left: 0;
                            top: 0;
                            position: absolute;
                            transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
                        }

                        &:checked {
                            --b: var(--active);
                            --bc: var(--active);
                            --d-o: .3s;
                            --d-t: .6s;
                            --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
                        }

                        &:hover {
                            &:not(:checked) {
                                &:not(:disabled) {
                                    --bc: var(--border-hover);
                                }
                            }
                        }

                        &:focus {
                            box-shadow: 0 0 0 var(--focus);
                        }

                        &:not(.switch) {
                            width: 21px;

                            &:after {
                                opacity: var(--o, 0);
                            }

                            &:checked {
                                --o: 1;
                            }
                        }

                    }

                    input[type='checkbox'] {
                        &:not(.switch) {
                            border-radius: 7px;

                            &:after {
                                width: 5px;
                                height: 9px;
                                border: 2px solid var(--active-inner);
                                border-top: 0;
                                border-left: 0;
                                left: 7px;
                                top: 4px;
                                transform: rotate(var(--r, 20deg));
                            }

                            &:checked {
                                --r: 43deg;
                            }
                        }

                        &.switch {
                            width: 38px;
                            border-radius: 11px;
                            padding: 10px 17px;
                            margin: 0 auto;

                            &:after {
                                left: 2px;
                                top: 2px;
                                border-radius: 50%;
                                width: 15px;
                                height: 15px;
                                background: var(--ab, var(--border));
                                transform: translateX(var(--x, 0));
                            }

                            &:checked {
                                --ab: var(--active-inner);
                                --x: 17px;
                            }

                            &:disabled {
                                &:not(:checked) {
                                    &:after {
                                        opacity: .6;
                                    }
                                }
                            }
                        }
                    }

                }
            }

        }

    }
}

@media screen and (max-width: 600px) {
    .EditProfileDoktorat {
        overflow: hidden;

        .EditModalStatic {
            .btnActive {
                margin-right: 0px;

                button {
                    width: 50%;
                }
            }

            .editPass {
                margin-top: 50px;
                border-radius: 12px;
                margin-right: 20px !important;
                margin-left: 20px !important;

                form {
                    width: 100% !important;

                    input {
                        margin: 5px;
                        border: 1px solid #e5e5e5;
                        border-radius: 10px;
                        background-color: transparent;
                        padding: 10px 20px;
                        margin: 10px 0;
                        font-size: 14px;
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 450px) {
    .EditProfileDoktorat {
        .titleContent {
            font-size: 21px;
        }

    }

}


@media screen and (max-width: 700px) {
    .EditProfileDoktorat {
        .EditModalStatic {
            .editForm {
                width: 100%;

                .btnOrari {
                    width: 45%;
                }
            }
        }
    }

}

/* Chrome, Safari, Edge, Opera */
.xona20::-webkit-outer-spin-button,
.xona20::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.emailiHover {
    &:hover {
        cursor: not-allowed;
    }
}