.editMyProfile {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 76%;
    right: 2.5%;

    .titulliNdrysho {
        text-align: left;
    }

    .terminetN {

        .btnActive {
            display: flex;
            margin-right: 164px;

            button {
                border-radius: 10px;
                margin: 0px 10px;
                padding: 5px 10px;
                border: unset;
                width: 25%;

                &:hover {
                    background-color: #FBD74D;
                    // color: white;
                }
            }

            .active {
                background-color: #FBD74D;
                color: black;
                font-size: 15px;
            }
        }
    }

    .imageContent {
        width: 70%;
        margin: 12px auto;

        .img-wrapper {
            height: 160px;
            width: 160px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 50%;
            border: #FBD74D 1px solid;
            margin: 0 auto;
        }

        .textProfile {
            margin-right: 150px;
        }
    }



    .editProfileFixed {
        position: fixed;
        top: 23.5%;
        bottom: 0;
        right: 0px;
        height: auto;
        overflow: auto;
        margin: 0 auto;

        .logoja {
            width: 70px;
        }

        form {
            width: 45%;
            display: block;
            margin-top: 35px;
            font-family: Lato;
            margin-bottom: 50px;

            .form-control {
                background: transparent;
                height: 50px;
                color: black;
                border: 2px #FBD74D solid;
                border-radius: 5px;
                padding-left: 20px;
                padding-right: 20px;

                &::-webkit-input-placeholder {
                    color: black;
                }

                &:hover,
                &:focus {
                    background: transparent;
                    outline: none;
                    box-shadow: none;
                    border-color: rgb(238, 198, 124);
                }
            }

            // .field-iconn {
            //     position: absolute;
            //         top: 28%;
            //         // right: 33px;
            //         left: 35%;
            //         transform: translateY(-50%);
            //         color: black;
            //         cursor: pointer;
            // }

            .form-group {
                position: relative;
            }

            .check_box {
                width: 100%;
                padding-left: 5px;
                // height: 70px;

                .radio-wrapper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                p {
                    margin-bottom: 0;
                    font-size: 1rem;
                }

                input {
                    width: 20px;
                    height: 20px;
                    accent-color: darkgoldenrod;

                }

                label#kushtet {
                    width: auto;
                    margin-bottom: 10px;
                    margin-left: 5px;
                    font-size: 17px;
                    padding-right: 22px;
                    color: #696969;
                    vertical-align: middle;
                    cursor: pointer;


                }

            }

            input {
                width: 100%;
                position: relative;
                border: 1px solid #e5e5e5;
                border-radius: 10px;
                background-color: transparent;
                padding: 10px 30px;
                margin: 14px 0;
                height: 70px;

                &:hover,
                &:focus {
                    outline: none;
                }
            }

            .PhoneInput {
                height: 80px;
            }

            ::placeholder {
                color: #cbcbcb;
                font-size: 17px;
            }

            label {
                width: 100%;

                select {
                    width: 100%;
                    height: 70px;
                    position: relative;
                    margin: 10px;
                    border: 1px solid #e5e5e5;
                    border-radius: 10px;
                    background-color: transparent;
                    padding: 10px 20px;
                    margin: 12px 0;
                    font-size: 1rem;
                    color: #696969;

                    &:hover,
                    &:focus {
                        outline: none;
                    }
                }
            }

            .title {
                position: fixed;
                color: #5a5a5a;
                font-size: 20px;
            }

            .register-button {
                width: 100%;
                height: 70px;
                margin: 10px 0;
                background-color: #fdcd70;
                border: none;
                border-radius: 10px;
                padding: 12px;
                // font-weight: bold;
                color: white;

                font-size: 20px;
            }

            a {
                text-align: center;
            }

            .forgot_pass p {
                margin-top: 15px !important;
            }

            span {
                hr {
                    width: 70%;
                }
            }

            .btn {
                margin: 20px 0px 12px 0px;
                // cursor: pointer;
                box-shadow: none !important;
                font-size: 15px;
                text-transform: uppercase;

                &.btnLogin {
                    width: 100%;
                    background-color: #FBD74D;
                    transition: .3s ease-in-out;
                    color: black;
                    font-weight: 600;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    border: 2px #FBD74D solid;
                }
            }
        }

        .tacbox {
            display: flex;
            padding: 1em;
            vertical-align: middle;
            max-width: 800px;
            align-items: center;

            label {
                padding-top: 5px;
                padding-left: 10px;
                font-size: 14px;
            }

            input {
                height: 2em;
                width: 2em;
                vertical-align: middle;
                accent-color: darkgoldenrod;
            }
        }
    }

    .register-form2 {
        display: flex;
        position: relative;
        top: 26%;
        bottom: 0;
        right: 0px;
        height: auto;
        overflow: auto;

        // background-color: #fff;
        // padding-bottom: 100px;

        .logoja {
            width: 70px;
        }

        form {
            width: 50%;
            display: block;
            margin-top: 35px;
            font-family: Lato;
            margin-bottom: 50px;

            .form-control2 {
                background: transparent;
                height: 50px;
                color: black;
                border: 2px #FBD74D solid;
                border-radius: 5px;
                padding-left: 20px;
                padding-right: 20px;

                &::-webkit-input-placeholder {
                    color: black;
                }

                &:hover,
                &:focus {
                    background: transparent;
                    outline: none;
                    box-shadow: none;
                    border-color: rgb(238, 198, 124);
                }
            }

            .field-icon{
                position: absolute;
                    margin-top: 40px;
                    // right: 33px;
                    // left: 47%;
                    left: 46%;
                    transform: translateY(-50%);
                    color: black;
                    cursor: pointer;
                    font-size: 15px;
            }

            .form-group {
                position: relative;
            }

            .check_box {
                width: 100%;
                padding-left: 5px;
                // height: 70px;

                .radio-wrapper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                p {
                    margin-bottom: 0;
                    font-size: 1rem;
                }

                input {
                    width: 20px;
                    height: 20px;
                    accent-color: darkgoldenrod;

                }

                label {

                    width: auto;
                    margin-bottom: 10px;
                    margin-left: 5px;
                    font-size: px;
                    padding-right: 22px;
                    color: #696969;
                    vertical-align: middle;


                }
            }

            input {
                width: 100%;
                position: relative;
                border: 1px solid #e5e5e5;
                border-radius: 10px;
                background-color: transparent;
                padding: 10px 30px;
                margin: 14px 0;
                height: 70px;

                &:hover,
                &:focus {
                    outline: none;
                }

                &::-webkit-input-placeholder {
                    color: grey;
                    font-size: 15px;
                }


            }

            .PhoneInput {
                height: 80px;
            }

            ::placeholder {
                color: #cbcbcb;
                font-size: 17px;
            }

            label {
                width: 100%;

                span {
                    font-size: 18px;
                }

                select {
                    width: 100%;
                    height: 70px;
                    position: relative;
                    margin: 10px;
                    border: 1px solid #e5e5e5;
                    border-radius: 10px;
                    background-color: transparent;
                    padding: 10px 20px;
                    margin: 12px 0;
                    font-size: 1rem;
                    color: #696969;

                    &:hover,
                    &:focus {
                        outline: none;
                    }
                }


            }

            .title {
                position: fixed;
                color: #5a5a5a;
                font-size: 20px;
            }

            .register-button {
                width: 100%;
                height: 70px;
                margin: 10px 0;
                background-color: #fdcd70;
                border: none;
                border-radius: 10px;
                padding: 12px;
                // font-weight: bold;
                color: white;

                font-size: 20px;
            }

            a {
                text-align: center;
            }

            .forgot_pass p {
                margin-top: 15px !important;
            }

            span {
                hr {
                    width: 70%;
                }
            }

            .btn {
                margin: 20px 0px 12px 0px;
                // cursor: pointer;
                box-shadow: none !important;
                font-size: 15px;
                text-transform: uppercase;

                &.btnLogin {
                    width: 100%;
                    background-color: #FBD74D;
                    transition: .3s ease-in-out;
                    color: black;
                    font-weight: 600;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    border: 2px #FBD74D solid;
                }
            }
        }

        .tacbox {
            display: flex;
            padding: 1em;
            vertical-align: middle;
            max-width: 800px;
            align-items: center;

            label {
                padding-top: 5px;
                padding-left: 10px;
                font-size: 14px;
            }

            input {
                height: 2em;
                width: 2em;
                vertical-align: middle;
                accent-color: darkgoldenrod;
            }
        }
    }

    .need-help {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px;

        i {
            font-size: 26px;
            color: #5a5a5a;
        }

        p {
            font-size: 16px;
            margin-top: 15px;
            padding: 10px;
            color: #5a5a5a;
        }
    }

    .help {
        width: 100%;
        display: flex;
        justify-content: center;

        a {

            &:hover {
                text-decoration: none;
            }
        }
    }

    .cc {
        text-align: center;
        margin: 40px 0px;
    }

}

@media screen and (max-width: 1000px) {
    .editMyProfile {
        overflow: hidden;

        .terminetN {
            .btnActive {
                margin-right: 0px;

                .active {
                    font-size: 12px;
                }

                button {
                    width: 50%;

                }
            }
        }

        .editProfileFixed{
            .editProfileFixed{
                // left: 221px!important;
            }
            form {
                width: 90% !important;

                .check_box {
                    .radio-wrapper {
                        flex-direction: column;
                        align-items: baseline;
                    }
                }
            }

            .checkbox_label {
                line-height: unset;
            }
        }


        // .register-form1 {
        //     // margin-top: 50px;
        //     border-radius: 12px;
        //     margin-right: 20px !important;
        //     margin-left: 20px !important;


        //     .login-form {
        //         margin: 0 50px;

        //         form {
        //             width: 70% !important;

        //             input {
        //                 margin: 5px;
        //                 border: 1px solid #e5e5e5;
        //                 border-radius: 10px;
        //                 background-color: transparent;
        //                 padding: 10px 20px;
        //                 margin: 10px 0;
        //                 font-size: 14px;
        //             }

        //             .title {
        //                 position: fixed;
        //                 font-size: 14px;
        //             }

        //             .login {
        //                 margin: 12px 0;
        //                 background-color: #fdcd70;
        //                 border: none;
        //                 border-radius: 10px;
        //                 padding: 10px;
        //                 font-weight: bold;
        //                 font-size: 14px;
        //             }

        //             .forgot_pass {
        //                 font-size: 15px;
        //             }

        //             .sign_up {
        //                 font-size: 14px;
        //             }

        //             .register {
        //                 font-size: 17px;
        //             }

        //             .acceptTerms {
        //                 display: none;
        //                 float: left;
        //                 width: 100%;

        //                 .acceptTermsCheck {
        //                     height: 27px;
        //                     border: 1px solid #e5e5e5;
        //                 }

        //                 .termsLink {
        //                     width: 80%;
        //                     white-space: nowrap;
        //                     padding: 0px !important;
        //                     font-size: 14px !important;
        //                 }
        //             }
        //         }
        //     }
        // }

        .register-form2 {
            margin-top: 50px;
            border-radius: 12px;
            margin-right: 20px !important;
            margin-left: 20px !important;

            form {
                width: 100% !important;

                input {
                    margin: 5px;
                    border: 1px solid #e5e5e5;
                    border-radius: 10px;
                    background-color: transparent;
                    padding: 10px 20px;
                    margin: 10px 0;
                    font-size: 14px;


                    
                }

            }

        }

    }

}
@media screen and (max-width: 760px) {
.editMyProfile{
    
    left: 15px!important;

    .editProfileFixed{
        left: 21px!important;
    }
}
}



/* Chrome, Safari, Edge, Opera */
.xona20::-webkit-outer-spin-button,
.xona20::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.emailiHover {
    &:hover {
        cursor: not-allowed;
    }
}




@media screen and (max-width: 1000px) {
.editMyProfile .register-form2 form .field-icon {
    margin-top: 36px;
    left: 89%;
   
}


}