.vizita {
    position: absolute;
    top: 10%;
    left: 1%;
    right: 0%;
    .tableResponsive {
        position: fixed;
        right: 0;
        bottom: 4%;
        top: 18%;
        height: auto;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 0;
        }
    }
    .list-item-head-new {
        background-color: #FBD74D;
        color: black;
        font-weight: 700;
        position: sticky;
        top:0;
        z-index: 9;
    }
    .list-item {
        padding: 10px 0;
        margin-left: 5px;
        width: 97%;
        background-color: white;
        &:hover {
            filter: drop-shadow(0 0 0.45rem gray);
            margin-bottom: 2px;
        }
        .tableContent {
            border-top: none;
        }
        .iconCursor {
            cursor: pointer;
        }
    }
    .listimi {
        margin-top: 20px;
        margin-left: 30px;
        margin-bottom: 50px;
        text-align: left;
        .list-item-head {
            background-color: #FBD74D;
            color: white;
            font-weight: 700;
            padding: 10px 0;
            position: fixed;
            left: 22%;
            right: 2%;
            // bottom: 4%;
            top: calc(1vw + 25%);
        }
        .tableContainer {
            position: fixed;
            right: 0;
            bottom: 4%;
            left: 0;
            top: 35%;
            height: auto;
            overflow-y: auto;
        }
    }
}
.stockNav {
    text-align: left;
    margin-top: calc(1vw + 20px);
    padding-left: calc(1vw + 20px);
    position: fixed;
    top: calc(1vw + 15%);
    button {
        border-radius: 10px;
        margin-right: calc(1vw + 10px);
        padding: 5px 10px;
        background-color: gainsboro;
        border: unset;
        &:hover {
            background-color: #FBD74D;
            color: white;
        }
    }
    .active {
        background-color: #FBD74D;
        color: white;
    }
}
.product-img {
    width: calc(1vw + 150px);
    border-radius: 50%;
    border: 10px gray solid;
}
