.AnuloTerminin {
    align-self: center;
    margin: 0 auto !important;
    overflow: hidden;
    border-radius: 10px;
    position: fixed;
    width: 21%;
    right: 0;
    left: 0;
    top: 34%;
    background-color: white;
    z-index: 99999;



    .anuloTerminin-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 4%;

        .anuloTermininContent {
            width: 70%;
            margin: 0 auto;
            margin-top: 70px;

            .button_delete_Po {
                width: 100%;
                border-radius: 5px;
                border: 2px #FBD74D solid;
                padding: 10px;
                transition: .3s ease-in-out;
                background-color: #FBD74D;
                color: white;
                font-weight: 600;

                &:hover {
                    width: 100%;
                    color: white;
                }
            }

            .button_delete_Jo {
                width: 100%;
                border-radius: 5px;
                border: 2px rgb(216, 0, 0) solid;
                padding: 10px;
                transition: .3s ease-in-out;
                background-color: rgb(216, 0, 0);
                color: white;
                font-weight: 600;

                &:hover {
                    width: 100%;
                    color: white;
                }
            }
        }
    }

}


@media only screen and (max-width: 1024px) {
    .AnuloTerminin {
        width: 90%;

        .anuloTerminin-wrapper {
            .anuloTermininContent {

                .button_delete_Po,
                .button_delete_Jo {
                    margin-bottom: 4%;
                }
            }
        }
    }
}