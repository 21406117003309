.register-form {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 12px 12px;
  margin-top: 52px;
   .logoja{width: 70px;}
   form {
     width: 50%;
     display: block;
     font-family: "Lato";
     margin-bottom: 50px;
       a {
            text-decoration: none;
            color: #FBD74D;
            font-weight: 500;
            &:hover {
              color: black;
            }
       }
       .form-control {
          background: transparent;
          // border: none;
          height: 50px;
          color: black;
          border: 2px #FBD74D solid;
          border-radius: 5px;
          padding-left: 20px;
          padding-right: 20px;

             &::-webkit-input-placeholder {
               color: black;
                }
                   &:hover,
                   &:focus {
                     background: transparent;
                     outline: none;
                     box-shadow: none;
                     border-color: rgb(238, 198, 124);
                   }
    }

    .field-icon {
      position: absolute;
      top: 57%;
      right: 15px;
      transform: translateY(-50%);
      color: black;
      cursor: pointer;
    }
    .form-groupi {
      position: relative;
    }
    .check_box {
      width: 100%;
      padding-left: 5px;
      .radio-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      p {
        margin-bottom: 0;
        font-size: 1rem;
      }
      input {
        width: 20px;
        height: 20px;
        accent-color: darkgoldenrod;

      }
      label#kushtet {
        width: auto;
        margin-bottom: 10px;
        margin-left: 5px;
        font-size: 17px;
        font-weight: 500;
        padding-right: 22px;
        color: #696969;
        vertical-align: middle;
      }
    }
    input {
      width: 100%;
      position: relative;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      background-color: transparent;
      padding: 10px 30px;
      margin: 14px 0;
      height: 70px;
        &:hover,
        &:focus {
          outline: none;
        }
    }
    .PhoneInput {
      height: 80px;
    }
    ::placeholder {
      color: #cbcbcb;
      font-size: 17px;
      font-weight: 500;
    }
    label {
      width: 100%;
      select {
        width: 100%;
        height: 70px;
        position: relative;
        margin: 10px;
        border: 1px solid #e5e5e5;
        border-radius: 10px;
        background-color: transparent;
        padding: 10px 20px;
        margin: 12px 0;
        font-size: 1rem;
        color: #696969;
        &:hover,
        &:focus {
          outline: none;
        }
      }
    }
    .title {
      color: #5a5a5a;
      font-size: 20px;
    }
    a {
      text-align: center;
    }
    .forgot_pass p {
      margin-top: 15px !important;
    }
    span {
      hr {
        width: 70%;
      }
    }
    .btn {
      margin: 20px 0px 12px 0px;
      cursor: pointer;
      box-shadow: none !important;
      font-size: 15px;
      text-transform: uppercase;
      &:hover,
      &:active,
      &:focus {
        outline: none;
      }
      &.btnLogin {
        width: 70%;
        transition: .3s ease-in-out;
        background-color: #FBD74D;
        color: white;
        font-weight: 600;
        border-radius: 5px;
        margin-bottom: 10px;
        border: 2px #FBD74D solid;
        &:hover {
          width: 100%;
          color: white;
        }
      }
    }
  }
  .tacbox {
    display: flex;
    padding: 1em;
    vertical-align: middle;
    max-width: 800px;
    align-items: center;
    label {
      padding-top: 5px;
      padding-left: 10px;
      font-size: 14px;
    }
    input {
      height: 2em;
      width: 2em;
      vertical-align: middle;
      accent-color: darkgoldenrod;
    }
  }
}
.need-help {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  i {
    font-size: 26px;
    color: #5a5a5a;
    }
  p {
    font-size: 16px;
    margin-top: 15px;
    padding: 10px;
    color: #5a5a5a;
  }
}
.help {
  width: 100%;
  display: flex;
  justify-content: center;
  a {

    &:hover {
      text-decoration: none;
    }
  }
}
.cc {
  text-align: center;
  margin: 40px 0px;
}
@media screen and (max-width: 900px) {
  .register-form {
    form {
      width: 100% !important;
      .check_box {
        .radio-wrapper {
          flex-direction: column;
          align-items: baseline;
        }
      }
    }
    .checkbox_label {
      line-height: unset;
    }
  }
  .register-form {
    margin-top: 50px;
    border-radius: 12px;
    margin-right: 20px !important;
    margin-left: 20px !important;
    .login-form {
      margin: 0 50px;
      form {
        width: 70% !important;
        input {
          margin: 5px;
          border: 1px solid #e5e5e5;
          border-radius: 10px;
          background-color: transparent;
          padding: 10px 20px;
          margin: 10px 0;
          font-size: 14px;
        }
        .title {
          font-size: 14px;
        }
        .login {
          margin: 12px 0;
          background-color: #fdcd70;
          border: none;
          border-radius: 10px;
          padding: 10px;
          font-weight: bold;
          font-size: 14px;
        }
        .forgot_pass {
          font-size: 15px;
        }
        .sign_up {
          font-size: 14px;
        }
        .register {
          font-size: 17px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .btnLogin {
    width: 100% !important;
  }
}
.xona20::-webkit-outer-spin-button,
.xona20::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}