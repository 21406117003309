.stafiAdmin {
    position: absolute;
    top: 9%;
    left: 20%;
    width: 75%;

    .tableResponsiveStafiAdmin {
        position: fixed;
        top: 18%;
        bottom: 0;
        right: 0px;
        height: auto;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 0;
        }
    }

    .terminetNav {
        text-align: left;
        // margin-top: 100px;
        margin-top: 141px;
        padding-left: 20px;

        button {
            border-radius: 10px;
            margin-right: 10px;
            padding: 5px 10px;
            border: unset;

            &:hover {
                background-color: #FBD74D;
                color: white;
            }
        }

        .active {
            background-color: #FBD74D;
            color: white;
        }
    }

    .list-item-head-new {
        background-color: #FBD74D;
        color: white;
        font-weight: 700;
        position: sticky;
        top: -1px;
        z-index: 9;

        .tableContent {
            color: black;
        }

    }

    .list-item {
        padding: 10px 0;
        margin-left: 10px;
        background-color: white;

        &:hover {
            filter: drop-shadow(0 0 0.45rem gray);
            margin-bottom: 2px;
        }

        .iconCursor {
            color: black;
            font-size: 18px;
        }

        .tableContentStafiAdmin {
            border-top: none;
        }

        .downloadIcon {
            text-decoration: none;
            color: black;

        }
    }

    .listimi {
        margin-top: 20px;
        margin-left: 30px;
        margin-bottom: 50px;
        text-align: left;

        .list-item-head {
            background-color: #FBD74D;
            color: white;
            font-weight: 700;
            padding: 10px 0;
            position: fixed;
            left: 22%;
            right: 2%;
            // bottom: 4%;
            top: calc(1vw + 25%);



        }


    }


}

.product-img {
    width: 150px;
    border-radius: 50%;
    border: 10px gray solid;
}

.addStaf {
    position: fixed;
    bottom: 2%;
    right: 2%;
    z-index: 1;
    border-radius: 50%;
    background-color: #FFF;
    color: #FBD74D;
    border: unset;
    padding: 22px 9px;
    border-radius: 50% !important;
    border: 4px #FBD74D solid;
    transition: .3s ease-in-out;
    font-size: 42px;
    line-height: 0px;


    // z-index: 99;

    &:hover {
        text-decoration: none;
        background-color: #FBD74D;
        border: 5px #FFF solid;
        color: #FFF;
        filter: drop-shadow(0 0.25rem 0.75rem gray);
    }
}