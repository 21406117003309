.forgotPassword {
    position: relative;
    top: 100px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 0;
    }
    form {
        width: 50%;
        display: block;
        font-family: Lato;
        .form-input {
            .email_input {
                width: 100%;
                border-radius: 5px;
                margin-bottom: 10px;
                border: 2px #FBD74D solid;
                padding: 10px;
                &::-webkit-input-placeholder {
                    color: black;
                }
                &:hover,
                &:focus {
                    background: transparent;
                    outline: none;
                    box-shadow: none;
                    border-color: rgb(238, 198, 124);
                }
            }
        }
        .button_forgot {
            width: 100%;
            border-radius: 5px;
            border: 2px #FBD74D solid;
            padding: 10px;
            transition: .3s ease-in-out;
            background-color: #FBD74D;
            color: white;
            font-weight: 600;
            &:hover {
                width: 100%;
                color: white;
            }
        }
    }
}