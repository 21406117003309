.Settings {
    position: absolute;
    top: 10%;
    left: 30%;
    right: 2.5%;

    .settingsContent {
        position: fixed;
        top: 18%;
        bottom: 0;
        right: 0px;
        height: auto;
        overflow: auto;
        margin: 0 auto;
        width: 100%;
        margin-left: 30px;

        form {
            width: 96%;

            .input-design {
                .timePickerSettings {
                    p {
                        color: #7e8092;
                    }
                }

                label {
                    p {
                        color: #7e8092;
                    }
                }

                .button_forgot {
                    width: 100%;
                    border-radius: 5px;
                    border: 2px #FBD74D solid;
                    padding: 10px;
                    transition: .3s ease-in-out;
                    background-color: #FBD74D;
                    color: black;
                    font-weight: 600;
                    width: 36%;
                    margin-left: 22px;

                }

                .inputt {
                    background: transparent;
                    height: 35px;
                    color: black;
                    border: 2px #FBD74D solid;
                    border-radius: 5px;
                    width: 45px;
                    padding: 10px 3px !important;
                    margin-top: 0px;
                    margin-bottom: 20px;
                    margin-left: 1.4%;
                    text-align: center;
                }

                @supports(-webkit-appearance: none) or (-moz-appearance: none) {
                    input[type='checkbox'] {
                        --active: #FBD74D;
                        --active-inner: #fff;
                        --focus: 2px rgba(39, 94, 254, .3);
                        --border: #BBC1E1;
                        --border-hover: #FBD74D;
                        --background: #fff;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        height: 21px;
                        outline: none;
                        display: inline-block;
                        vertical-align: top;
                        position: relative;
                        margin: 0;
                        cursor: pointer;
                        border: 1px solid var(--bc, var(--border));
                        background: var(--b, var(--background));
                        transition: background .3s, border-color .3s, box-shadow .2s;

                        &:after {
                            content: '';
                            display: block;
                            left: 0;
                            top: 0;
                            position: absolute;
                            transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
                        }

                        &:checked {
                            --b: var(--active);
                            --bc: var(--active);
                            --d-o: .3s;
                            --d-t: .6s;
                            --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
                        }

                        &:hover {
                            &:not(:checked) {
                                &:not(:disabled) {
                                    --bc: var(--border-hover);
                                }
                            }
                        }

                        &:focus {
                            box-shadow: 0 0 0 var(--focus);
                        }

                        &:not(.switch) {
                            width: 21px;

                            &:after {
                                opacity: var(--o, 0);
                            }

                            &:checked {
                                --o: 1;
                            }
                        }

                    }

                    input[type='checkbox'] {
                        &:not(.switch) {
                            border-radius: 7px;

                            &:after {
                                width: 5px;
                                height: 9px;
                                border: 2px solid var(--active-inner);
                                border-top: 0;
                                border-left: 0;
                                left: 7px;
                                top: 4px;
                                transform: rotate(var(--r, 20deg));
                            }

                            &:checked {
                                --r: 43deg;
                            }
                        }

                        &.switch {
                            width: 38px;
                            border-radius: 11px;

                            &:after {
                                left: 2px;
                                top: 2px;
                                border-radius: 50%;
                                width: 15px;
                                height: 15px;
                                background: var(--ab, var(--border));
                                transform: translateX(var(--x, 0));
                            }

                            &:checked {
                                --ab: var(--active-inner);
                                --x: 17px;
                            }

                            &:disabled {
                                &:not(:checked) {
                                    &:after {
                                        opacity: .6;
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }

    }

}

.xona20::-webkit-outer-spin-button,
.xona20::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


@media only screen and (max-width: 500px) {

    .Settings {
        .settingsContent {
            margin-left: 11px;

            form {

                .input-design {
                    .button_forgot {
                        width: 90%;
                        margin-left: 4px;
                    }

                    .inputt {
                        bottom: 13px;
                        margin-left: 13.4%;

                    }
                }
            }
        }


    }
}

@media only screen and (max-width: 760px) {
    .Settings {
        .settingsContent {
            // margin-left: 0px;

            form {
                .input-design {
                    .inputt {
                        margin-left: 11.4%;

                    }
                }
            }
        }

    }
}



@media only screen and (max-width: 900px) {
    .Settings {
        .settingsContent {
            // margin-left: 0px;

            form {
                .input-design {
                    .inputt {
                        margin-left: 15.4%;

                    }
                }
            }
        }

    }
}