// @font-face {
//     font-family: "Medium";
//     src: local("HeadlandOne-Regular"),
//         url("../../fonts/HeadlandOne-Regular.ttf") format("woff");
// }

// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&family=Viga&display=swap');
.menu {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 80px;
    background-color: white;
    filter: drop-shadow(0 0 0.75rem gray);
    z-index: 9999;

    a {
        color: gray;
        font-weight: 600;

        &:hover {
            color: green;
        }
    }

}

.mobile-menu {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 80px;
    background-color: white;
    filter: drop-shadow(0 0 0.75rem gray);
    z-index: 9999;

    a {
        color: gray;
        font-weight: 600;

        &:hover {
            color: green;
        }
    }

    .mobileMenuWrapper {
        position: fixed;
        top: 80px;
        left: 0;
        right: 0;
        height: auto;
        background-color: white;

        a {
            width: 100%;
            float: left;
            padding: 10px 20px;
        }

        a:hover {
            background-color: green;
            color: white;
        }
    }

    @media only screen and (max-width: 750px) {
        display: inline-block;
    }
}

.admin-menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 20.1%;
    height: auto;
    // overflow-y: auto;
    background-color: #F2F2F7;
    color: #FBD74D;
    z-index: 9999;

    //   .test {
    //     a{

    //         img{

    //             left: 21%;
    //             width: 39%;
    //             top: 27px;
    //             margin-left: 0px;
    //                 }
    //         }
    //     }
    // filter: drop-shadow(-30px -30px -30px gray);
    // box-shadow: inset -3px 0px 28px gray;

 
    
    .logoTermin {
    width: 100%;
        margin: 20px;
        margin-left: 40px;
        height: 50px;
        position: sticky;
        margin-top: 0px;
        display: flex;

        .dataTermin {
            width: 20%;
            height: 110px;
            background-color: #FBD74D;

            .demo3 {
                margin-top: 60px;
                color: white;
                font-size: 33px;
                line-height: 10px;
                font-family: 'Viga',
                    sans-serif;;
                z-index: 1;
                position: relative;
            }

            .demo4 {
                margin-top: -25px;
                font-size: 20px;
                font-family: 'Viga',
                    sans-serif;;
                color: #fbd74d;
            }

            .demo5 {
                margin-top: -22px;
                font-size: 18px;
                font-family: 'Viga',
                    sans-serif;
                color: white;
                margin-bottom:24px;
            }
        }

        .termineImg {
            width: 70%;
            margin-top: 61px;

            img {
                width: 70%;
                    margin-right: 100px;                                                                                
            }

        }

    }


    @media only screen and (max-width: 750px) {
        width: 100% !important;
        right: 0;
        z-index: 99;
    }


    ul {
        // position: fixed;
        // // width: 100%;
        // left: 0;
        // top: 25%;
        // bottom: 0px;
        margin-top: 50%;
        height: auto;
        overflow-y: auto;
        // margin-top: 15px;
        list-style-type: none;
        text-align: left;

        @media only screen and (max-width: 750px) {
            margin-top: 180px;
        }

        .subMenuItems {
            // margin-left: 20px;
            // width: 80%;
            padding-left: 20px;
            // a {
            //     li {
            //     //     transition: .3s ease-in-out;
            //     //     padding: 10px 25px;
            //     //     color: #FBD74D;
            //     }

            //     // &:hover {
            //     //     // a {color: white}
            //     //     background-color: #FBD74D;
            //     //     color: white;
            //     //     padding-left: 30px;
            //     // }

            // }
        }

                

        a {
            color: #FBD74D;
            text-decoration: none;
            font-weight: 500;

            li.active {
                // a {color: white}
                background-color: #FBD74D;
                color: black;
                padding-left: 15px;
                border-radius: 3px 0 0 3px;
                color: black;
                // margin-top: -5px;

            }

            button.logout {
                // a {color: white}
                 background-color: white;
                color: black;
                font-weight: 600;
                padding-left: 4px 4px;
                border-radius: 5px 5px 5px 5px;
                border: white;
            }

            li {
                transition: .3s ease-in-out;
                padding: 10px 15px;
                color: #101928;
                font-family: system-ui;



                &:hover {
                    // a {color: white}
                    background-color: #FBD74D;
                    color: white;
                    padding-left: 15px;
                    border-radius: 3px 0 0 3px;
                    margin: 2px;
                }

            }

            &:hover {
                // a {color: white}
                background-color: white;
                color: white;
            }


        }
    }

    .er {
        bottom: 2%;
        position: absolute;
        width: 100%;
        font-family: system-ui;
        color: #101928;
        margin-left: 13px;

        &:hover {
            // a {color: white}
            background-color: #FBD74D;
            color: white;
            padding-left: 30px;
            border-radius: 20px 0 0 20px;
            margin: 2px;
            padding: 10px 0 10px 15px;
        }

        &:hover {
            // a {color: white}
            background-color: #FBD74D;
            color: white;
        }
    }
}

.menuButton{
    position: fixed;
    margin-top: 24px;
        z-index: 99999;
        border-radius: 50%;
        background-color: #F2F2F7;
        color: black;
        border: unset;
        padding: 25px 25px;
        border-radius: 20% !important;
        // border: 2px #101928 solid;
        transition: .3s ease-in-out;
        font-size: 10px;
        // margin-left: -2.20%;
        background-image: url('../../images/close.svg');
        background-repeat: no-repeat;
        background-position: center;

        .close{
                left: 20%;
                  
                    
        }
}
.menuButtonBars {
    position: fixed;
    margin-top: 24px;
    z-index: 99999;
    border-radius: 50%;
    background-color: #F2F2F7;
    color: black;
    border: unset;
    padding: 25px 25px;
    border-radius: 20% !important;
    // border: 2px #101928 solid;
    transition: .3s ease-in-out;
    font-size: 10px;
    margin-left: 1%;
    background-image: url('../../images/float.svg');
        background-repeat: no-repeat;
        background-position: center;
    
    &:hover {

        // filter: drop-shadow(0 0.25rem 0.75rem gray);
        background-color: #F2F2F7;
        // border: 5px #FFF solid;
        color: #FFF;
    }
}

.lg-line {
    // border-top: 1px solid rgba(0, 0, 0, 0.27);
    width: 80%;
    margin-left: 0;
    padding-left: 0;
    // margin-top: 90px;
    position: absolute;
    bottom: 2%;
    
}

.log1 {
    margin-top: 90px;
}


@media only screen and (max-width: 1458px) and (min-width: 1312px){
.admin-menu {
.logoTermin{
     .termineImg img {
        width: 74%;
        margin-top: -8px;

    }
}
}
}

@media only screen and (max-width:1000) and (min-width:750) {
    .admin-menu {
        .logoTermin {
            .termineImg {
                img {
                    width: 79%;
                }
            }

        }
    }
}

@media only screen and (max-width:946px) and (min-width:760) {
    .admin-menu {
        .logoTermin {
            .termineImg {
                img {
                    width: 30%;
                }
            }

        }
    }
}
@media only screen and (max-width:750px)and (min-width:320px) {
    .admin-menu {
        .logoTermin {
            .dataTermin {
                width: 15%;
            }

            .termineImg {
                width: 70%;
                margin-top: 60px;

                img {
                    width: 61%;
                    margin-right: 1200px;
                }

            }

        }
    }

    .menuButton {
        // top: 10.8%;
        display: none;

        .closeMenu {
            display: none;
            
        }
    }

}

 @media only screen and (max-width: 320px) {
     .admin-menu {
        ul{
            margin-top: 141px;
        }
         .lg-line {
             bottom: 0;
         }
     }
 }
// @media only screen and (max-width:460) and (min-width:320px) {
//     .admin-menu {
//         .logoTermin {
//             .termineImg {
//                 img {
//                     width: 60%;
//                 }
//             }

//         }
//     }
// }
