.caktoterminin {
    position: absolute;
    top: 10%;
    left: 1%;
    right: 2.5%;
    .terminetContainer {
        position: fixed;
        top: 16%;
        bottom: 0;
        right: 0px;
        height: auto;
        overflow: auto;
        .terminetRow {
            margin: 0 !important;
            .categoryName {
                text-decoration: none;
                color: black;
                .kategori-items {
                    cursor: pointer;
                    background: #F2F2F7;
                    height: 100px;
                    color: #FFF;
                    border-radius: 20px;
                }
                 .circle {
                    cursor: pointer;
                    position: relative;
                    background: white;
                    height: 70px;
                    width: 70px;
                    margin: 12px auto;
                    color: #FFF;
                    border-radius: 80px;
                }
                .kategoryTitle {
                    font-size: 16px;
                    font-weight: 600;
                    margin-right: 0.5px;
                }
                .katogoryIcon {
                    color: #FFF
                }
                .CompanyLogo {
                    width: 42px;
                    margin-top: 11px;
                    position: relative;
                }
            }
        }
    }
}

@media screen and (max-width: 760px) {
    .kategori-items {
        cursor: pointer;
        background: #F2F2F7;
        height: unset;
        width: 94%;
        margin: 12px auto;
        color: #FFF;
        border-radius: 20px;
    }
}