// .from2 {
//     position: fixed;
//     top: 3%;
//     // border-radius: 20px 0 0 20px;
//     // background-color: #F2F2F7;
//     color: white;
//     z-index: 9;
//     // filter: drop-shadow(0 0 0.15rem gray);
//     right: 1210px;

//     .input-type {
//         display: none;
//     }

//     .refresh {
//         position: absolute;
//         left: 0;
//         border-radius: 0% 0% 0% 0%;
//         height: 30px;
//         width: 200px;
//         background-color: #F2F2F7;
//         color: white;

//         i {
//             font-size: 15px;
//             line-height: unset;
//             padding:5px 160px 5px 0px;
//             cursor: pointer;
//             color: black;
//         }

//         // &:hover {
//         //     background-color: #F2F2F7;
//         // }
//     }

// }

.fromTo {
    position: fixed;
    margin-right: 1120px;
    top: 3%;
    left: 6%;
    height: 20px;
     width: 90px;
    border-radius: 0px 0px 0px 0px;
    background-color: #F2F2F7;
    // color: white;
    z-index: 9;
    // filter: drop-shadow(0 0 0.15rem gray);

    .refresh {
        position: absolute;
        left: 0;
        border-radius: 30%;
        height: 40px;
        width: 40px;
        background-color: #F2F2F7;
        color: white;

        // i {
        //     font-size: 25px;
        //     line-height: unset;
        //     padding: 2px;
        //     transition: .3s ease-in-out;
        //     cursor: pointer;

        // }

        // button {
        //     color: white;
        //     border: unset;
        //     background: unset;
        //     line-height: unset;
        // }
    }

}