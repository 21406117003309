@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&family=Viga&display=swap');
.login {
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    overflow-y: scroll;
    bottom: 0;
    margin: 0 auto;
    form {
        a {
            text-decoration: none;
            color: #fbd74d;
            font-weight: 500;
          }
    }
    .login-wraper {
        margin: 0 auto;
        margin-top: 20px;
        max-width: 500px;
        border-radius: 10px;
        padding: 20px;
        position: relative;
        .field-icon {
            position: absolute;
            top: 99px;
            right: 33px;
            transform: translateY(-50%);
            color: black;
            cursor: pointer;
        }
        input,
        button {
            width: 100%;
            border-radius: 5px;
            margin-bottom: 10px;
            border: 2px #bdbdc6 solid;
            padding: 10px;
        }
        button {
            width: 70%;
            transition: .3s ease-in-out;
            background-color: #fbd74d;
            color: black;
            font-weight: 600;
            border: none;
            &:hover {
                width: 100%;
                color: white;
            }
        }
    }
}
@media screen and (max-width: 320px) {
    .login {
        .foto 
          {
            width: 60%;
          }
    }
}
.pt-5,
.py-5 {
    padding-top: 3rem !important;
    width: 250px;
}
.demo {
    margin-top: -20px;
    margin-bottom: 17px;
    color: white;
    font-size: 40px;
    font-family: 'Viga',
        sans-serif;
    z-index: 1;
    position: relative;
    margin-left: -20px;
}
@media screen and (max-width: 320px) {
    #demo {
        width: 20px;
    }
}
.ola {
    z-index: 1;
    background-color: #FBD74D;
    width: 1px;
    padding: 55px 35px;
    margin: 20px;
    margin: 0 auto;
    height: 10px;
    position: sticky;
    margin-top: 0px;
}
.demo2 {
    font-size: 20px;
    font-family: 'Viga',
        sans-serif;
    color: #fbd74d;
    margin-top: -7px;
    margin-left: -24px
}
.demo7 {
    font-size: 20px;
    font-family: 'Viga',
        sans-serif;
    color: white;
    margin-top: -79px;
    margin-left: -18px
}
@media only screen and (max-width: 912px) {
    .ola {
        z-index: 1;
        background-color: #FBD74D;
        width: 1px;
        padding: 58px 40px;
        margin: 20px px;
        height: 10px;
        position: sticky;
        margin-top: 0px;
    }
}
a.pe-2 {
    color: black !important;
}