.ditetModal {
    align-self: center;
    overflow: auto;
    position: relative;
    top: 0;
    bottom: 0;
    background-color: white;
    z-index: 99999;
    height: 100vh;
    padding: 15px;
    /* float: right; */
    /* left: 0; */
    right: 0;
    width: 35%;
    position: fixed;
    justify-content: end;

    h1,
    h2,
    h3,
    h4,
    h5,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5 {
        line-height: 1.5;
        font-weight: 400;

        color: black;
    }

    // .bg-primary {
    //     background: #ffab1c !important;
    // }

    .heading-section {
        font-size: 27px;
    }

    // .closeAddModal {
    //     position: absolute;
    //     right: 14px;
    //     top: 10px;
    //     width: 50px;
    //     height: 50px;
    //     border-radius: 50%;
    //     border: unset;
    //     color: black;
    //     font-size: 20px;
    //     background: #FBD74D;
    //     z-index: 9999999;

    // }

    .ditetPunes {
        width: 93%;
        margin: 0 auto;
        margin-top: 70px;


        form {

            .input-design {
                .teksti {
                    width: 95%;
                }

                .form-control {
                    background: transparent;
                    // height: 50px;
                    padding: 3px;
                    color: black;
                    border: 2px #FBD74D solid;
                    border-radius: 5px;
                    // padding-left: 20px;
                    // padding-right: 20px;
                }

                .text {
                    width: 14.2%;
                    text-align: center;
                }

                .checkboxForm {
                    width: 14.2%;
                    text-align: center;
                }

                label {
                    p {
                        color: #7e8092;
                    }
                }

                @supports(-webkit-appearance: none) or (-moz-appearance: none) {
                    input[type='checkbox'] {
                        --active: #FBD74D;
                        --active-inner: #fff;
                        --focus: 2px rgba(39, 94, 254, .3);
                        --border: #BBC1E1;
                        --border-hover: #FBD74D;
                        --background: #fff;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        height: 21px;
                        outline: none;
                        display: inline-block;
                        vertical-align: top;
                        position: relative;
                        margin: 0;
                        cursor: pointer;
                        border: 1px solid var(--bc, var(--border));
                        background: var(--b, var(--background));
                        transition: background .3s, border-color .3s, box-shadow .2s;

                        &:after {
                            content: '';
                            display: block;
                            left: 0;
                            top: 0;
                            position: absolute;
                            transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
                        }

                        &:checked {
                            --b: var(--active);
                            --bc: var(--active);
                            --d-o: .3s;
                            --d-t: .6s;
                            --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
                        }

                        &:hover {
                            &:not(:checked) {
                                &:not(:disabled) {
                                    --bc: var(--border-hover);
                                }
                            }
                        }

                        &:focus {
                            box-shadow: 0 0 0 var(--focus);
                        }

                        &:not(.switch) {
                            width: 21px;

                            &:after {
                                opacity: var(--o, 0);
                            }

                            &:checked {
                                --o: 1;
                            }
                        }

                    }

                    input[type='checkbox'] {
                        &:not(.switch) {
                            border-radius: 7px;

                            &:after {
                                width: 5px;
                                height: 9px;
                                border: 2px solid var(--active-inner);
                                border-top: 0;
                                border-left: 0;
                                left: 7px;
                                top: 4px;
                                transform: rotate(var(--r, 20deg));
                            }

                            &:checked {
                                --r: 43deg;
                            }
                        }

                        &.switch {
                            width: 38px;
                            border-radius: 11px;
                            padding: 10px 17px;
                            margin: 0 auto;

                            &:after {
                                left: 2px;
                                top: 2px;
                                border-radius: 50%;
                                width: 15px;
                                height: 15px;
                                background: var(--ab, var(--border));
                                transform: translateX(var(--x, 0));
                            }

                            &:checked {
                                --ab: var(--active-inner);
                                --x: 17px;
                            }

                            &:disabled {
                                &:not(:checked) {
                                    &:after {
                                        opacity: .6;
                                    }
                                }
                            }
                        }
                    }

                }
            }

            .btn {
                // margin: 20px 0px 12px 0px;
                // box-shadow: none !important;
                // font-size: 15px;
                // text-transform: uppercase;

                &.btnLogin {
                    width: 30%;
                    background-color: #FBD74D;
                    transition: .3s ease-in-out;
                    color: black;
                    font-weight: 600;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    border: 2px #FBD74D solid;
                }

                &:hover {
                    width: 50%;
                    color: black;
                }
            }
        }
    }

    // .addModal-wrapper {

    //     .login-wrap {
    //         width: 60%;
    //         margin: 0 auto;



    //         .form-group {
    //             position: relative;


    //         }

    //         .field-icon {
    //             position: absolute;
    //             top: 50%;
    //             right: 15px;
    //             transform: translateY(-50%);
    //             color: black;
    //             cursor: pointer;
    //         }

    //         .form-control {
    //             background: transparent;
    //             border: none;
    //             height: 50px;
    //             color: black;
    //             border: 1px solid #ffab1c;
    //             border-radius: 12px;
    //             padding-left: 20px;
    //             padding-right: 20px;

    //             &::-webkit-input-placeholder {
    //                 color: black;
    //             }


    //             &:hover,
    //             &:focus {
    //                 background: transparent;
    //                 outline: none;
    //                 box-shadow: none;
    //                 border-color: rgb(238, 198, 124);
    //             }


    //         }

    //         .btn {
    //             margin: 20px 0px 12px 0px;
    //             cursor: pointer;
    //             box-shadow: none !important;
    //             font-size: 15px;
    //             text-transform: uppercase;

    //             &:hover,
    //             &:active,
    //             &:focus {
    //                 outline: none;
    //             }

    //             &.btnLogin {
    //                 background: #ffab1c !important;
    //                 // border: 1px solid#ffab1c !important;
    //                 color: rgb(255, 255, 255) !important;

    //                 &:hover {
    //                     // border: 1px solid#ffab1c;
    //                     background: rgb(255, 199, 95) !important;
    //                 }


    //             }
    //         }
    //     }
    // }
}

@media only screen and (max-width: 750px) {
    .ditetModal {
        width: 100%;
    }
}