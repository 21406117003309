.timeSlotsContent {
    position: absolute;
    top: 10%;
    left: 2%;
    right: 2.5%;
    .maincontent {
        left: 2.5%;
        position: fixed;
        top: 15%;
        bottom: 0;
        right: 0px;
        height: auto;
        margin-top: 37px;
        overflow: auto;
        padding: 20px 25px;
        &::-webkit-scrollbar {
             width: 0;
        }
        .doctorInformations {
             background-color: white;
             border-radius: 12px;
             padding: 19px;
             box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
              .img-wrapper {
                  height: 28vh;
                  width: 28vh;
                  border-radius: 50%;
                  border: #FBD74D 1px solid;
                  object-fit: cover;
                  margin: 15px auto;
              }
             .pozita {
                 border-bottom: 1px solid #e8e8e8;
                 text-align: left;
                 padding: 0px 23px;
                 word-break: break-all;
             }
             .infos {
                 text-align: left;
                 padding: 0px 23px;
                 .info {
                     color: #5a5a5a;
                     margin: 0px !important;
                 }
                 .doctor-Info {
                     font-size: 19px;
                 }
             }
        }
        .appointmentInfo {
             .time {
                 border-radius: 0px 0px 12px 12px;
             }
             .time-slot {
                 border-radius: 12px 12px 0px 0px;
             }
             .book-appointment-row {
                 background-color: white;
                 padding: 25px;
                 box-shadow: rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 8%) 0px 0px 0px 1px;
                 .daysOfWeek {
                     font-size: 18px;
                     font-weight: bold;
                 }
                 .timeslots-wrapper {
                     display: flex;
                     flex-direction: column;
                     .timeslots {
                         border-radius: 15px;
                         padding: 9px;
                         padding-bottom: 10px;
                         margin-bottom: 10px;
                         .timeSlotsContainer {
                             padding: 1%;
                             .timeslot {
                                 box-sizing: border-box;
                                 text-align: center;
                                 margin-bottom: 10px;
                                 padding: 3px;
                                 border-radius: 8px;
                                 cursor: pointer;
                                 border: 2px #e3e3e3 solid;
                                 background-size: 100%;
                                 &.disabled {
                                    text-decoration: line-through;
                                    color: red;
                                    cursor: not-allowed;
                                 }
                                  &.active {
                                    padding: 0;
                                    background: #FBD74D;
                                    color: black;
                                    border: 2px #FBD74D solid;
                                 }
                             }
                         }
                     }
                     button.submit-appointment {
                         text-align: center;
                         border: 0;
                         border-radius: 12px;
                         background-color: #FBD74D;
                         padding: 15px;
                         color: black;
                         font-size: 18px;
                     }
                 }
                 .info-inner {
                     width: 100%;
                     padding: 20px;
                     background-color: rgb(255 234 195);
                     border-radius: 15px;
                     display: flex;
                     justify-content: center;
                     align-items: center;
                     p {
                         font-style: italic;
                         font-size: 16px;
                         max-width: 70%;
                         text-align: center;
                         font-weight: 600;
                         color: black;
                     }
                 }
                 .appointment-day {
                     cursor: pointer;
                     h3 {
                         font-size: 16px;
                         text-align: center;
                         color: #FBD74D;
                     }
                     h4 {
                         font-size: 14px;
                         text-align: center;
                     }
                 }
                 .selected-day {
                     background: #FBD74D;
                     padding: 10px;
                 }
                 .slick-prev {
                     left: -15px;
                 }
                 .slick-prev::before {
                     margin-left: 3px;
                 }
                 .slick-next::before {
                     margin-right: 3px;
                 }
             }
             .selected-day {
                 border-radius: 10px;
             }
             .slick-prev {
                 left: -15px;
             }
             .slick-prev::before {
                 content: url("../../../images/slider-left.svg") !important;
                 margin-left: 3px;
             }
             .slick-next::before {
                 content: url("../../../images//slider-right.svg") !important;
                 margin-right: 3px;
             }
        }
        .aboutAppointment {
             p {
                 font-style: italic;
             }
             .textareaDesign {
                 border: 1px solid #e3e3e3;
                 border-radius: 12px;
                 padding: 6px;
                 width: 100%;
                 min-height: 90px;
                 &:focus {
                     border: 1px solid #e3e3e3 !important;
                     outline: none !important;
                 }
                 &:active {
                     border: 1px solid #e3e3e3 !important;
                 }
             }
        }
    }
}

@media (min-width: 901px) and (max-width: 1299px) {
    .col-lg-4 {
        padding-right: 0px;
    }
}

@media only screen and (max-width: 980px) {

    .maincontent {


        .appointmentInfo {
             padding: 0px !important;
        }
    }

    .book-appointment-row {
        .daysOfWeek {
             font-size: 16px;
             font-weight: bold;
        }
    }

    .working-hours {
        .single-box {
             &.contact-box {
                 margin-top: 40px;
             }
        }
    }
}


@media only screen and (max-width: 500px) {

    .timeSlotsContent {
        .titleContent {
             font-size: 22px;
             margin-top: 4px;
        }

        .col-6 {
             padding: 0px 3px;
        }
    }

}