.riktheFjalkalimin {
    font-family: Lato;
   
    .riktheMsg {
        position: relative;
        top: 150px;
        bottom: 0;
        width: 100%;
        display: block;
        font-family: Lato;
        height: auto;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 0;
        }
        .button_riktheFjalkalimin{
            width: 20%;
            border-radius: 5px;
            border: 2px #FBD74D solid;
            padding: 10px;
            transition: .3s ease-in-out;
            background-color: #FBD74D;
            color: white;
            font-weight: 600;
            &:hover {
                width: 30%;
                color: white;
            }
        }
    }
}

@media only screen and (max-width: 750px) {
    .riktheFjalkalimin{
        .riktheMsg {
         .button_riktheFjalkalimin{
            width: 90%;
        }
      }
    }
  }