.App {
  text-align: center;
  font-family: 'Lato';

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

// <<<<<<< Updated upstream // ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
//   background-color: #F5F5F5;
//   border-radius: 10px;
// }

// ::-webkit-scrollbar {
//   width: 7px;
//   background-color: #F5F5F5;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   background-color: #FFF;
//   background-image: -webkit-gradient(linear,
//       30% 0%,
//       75% 84%,
//       from(#fac800),
//       to(#fa8d00),
//       color-stop(.6, #faa300))
// }

// .pages {
//   // position: fixed;
//   // top: 10%;
//   // left:0;
//   // right:0;
//   // bottom: 0;
//   // height: auto;
//   // overflow-y: auto;
//   // filter: drop-shadow(0 0 0.75rem gray);
// }
// =======>>>>>>>Stashed changes 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.title {
  position: absolute;
  top: -90%;
  margin-left: 2%;

  @media only screen and (max-width: 750px) {
    // display: inline-block;
    // width: 100% !important;
    right: 5% !important;

  }
}

.wholePage {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.376);
  filter: blur(1rem);
  -webkit-filter: blur(4px);
  margin: auto;

}


.titujt {
  padding-left: 26px;
  text-align: left;
  margin-top: 10px;
}

.input-type {
  display: flex;
  margin-right: 25px;
  justify-content: end;

  .search-area {
    margin-top: 24px;
    height: 50px;
    border: none;
    border-radius: 10px;
    padding: 7px 52px 7px 40px;
    width: 255px;
    background-color: #F2F2F7;
    background-image: url('./images/search.svg');
    background-size: 18px;
    background-position: left 10px center;
    background-repeat: no-repeat;
  }
}
.closeModal {
  position: absolute;
  top: 2%;
  // right: 6.5%;
  left: 36px;
  width: 41px;
  height: 50px;
  border-radius: 50%;
  border: unset;
  color: white;
  font-size: 20px;
  z-index: 9999999;
  cursor: pointer;
}
.goBack {
  position: fixed;
  margin-top: 24px;
  border-radius: 50%;
  background-color: #F2F2F7;
  color: black;
  border: unset;
  left: 80px;
  padding: 25px 25px;
  border-radius: 20% !important;
  transition: 0.3s ease-in-out;
  font-size: 10px;
  background-image: url('./images/back-arrow.svg');
  background-size: 18px;
  background-position: left;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &:hover {
    color: #FFF;
  }
}

.admin-containter {

  position: fixed;
  top: 5%;
  left: 25%;

}



.addButton {
  position: fixed;
  bottom: 2%;
  right: 2%;
  z-index: 1;
  border-radius: 50%;
  background-color: #FFF;
  color: #FBD74D;
  border: unset;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  border: 4px #FBD74D solid;
  transition: .3s ease-in-out;
  font-size: 42px;
  line-height: 0px;
  padding: 1px 6px;
  padding-bottom: 8px;



  &:hover {

    background-color: #FBD74D;
    border: 5px #FFF solid;
    color: #FFF;
    filter: drop-shadow(0 0.25rem 0.75rem gray);
  }

  &:focus {
    outline: none;
  }
}


.mymodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, .8);
  z-index: 99999;
  width: 100%;
  height: 100%;


  

  .mymodal-wrapper {
    .deleteModalButton {
      height: 50px;
      width: 50px;
      border: unset;
      color: white;

    }

    .editModalButton {
      height: 50px;
      width: 50px;
      border: unset;
      color: white;

    }

    form {
      margin: 0 auto;
      width: 80%;
    }

    margin: 0 auto;
    margin-top: 50px;
    width: 80%;
    max-height: 80%;
    // overflow-y: scroll;
    overflow-x: scroll;
    filter: drop-shadow(0 0 0.75rem gray);
    // background-color: #FBD74D;
    background-color: white;
    z-index: 999999;
  }
}

.editButton {
  background: unset;
  color: #FBD74D;
  border: unset;
  transition: .3s ease-in-out;

  &:hover {
    font-size: 18px;
  }
}

.change-image-btn {
  position: absolute;
  left: 43%;
  bottom: 0;
  border: none;
  // border-radius: 50%;
  width: 30px;
  height: 30px;
  // padding: 10px;
}

.hideme {
  display: none;
  transition: ease-in-out .6s;

}

.showme {
  transition: ease-in-out .3s;

  &:hover {
    .hideme {
      display: inline-block;
    }

  }
}


.paginationn {
  position: sticky;
  left: 0%;
  z-index: -9;
  bottom: 24px;
  width: 100% !important;
}
@media only screen and (max-width: 707px) {
  .paginationn{
    bottom:0;
  }
}



.paginationBttns {
  list-style: none;
  display: flex;
  justify-content: end;
  margin: 30px 87px 0px 0px;
  @media only screen and (max-width: 750px) {
    margin: 0px;
  }
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #FBD74D;
  color: #FBD74D;
  cursor: pointer;
}

.paginationBttns a :hover {
  color: white !important;
  background-color: #FBD74D;
}

.paginationActive a {
  color: white !important;
  background-color: #FBD74D;
}

.paginationDisabled a {
  display: none;
}

.fromTo {
  position: fixed;

  right: 0;
  top: 3px;
  left: 70%;
  height: 40px;
  border-radius: 50px 0 0 50px;
  background-color: #FBD74D;
  color: white;
  z-index: 9;
  filter: drop-shadow(0 0 0.15rem gray);

  // .refresh {
  //   position: absolute;
  //   left: 0;
  //   border-radius: 50%;
  //   height: 40px;
  //   width: 40px;
  //   background-color: #FBD74D;
  //   color: white;

  //   i {
  //     font-size: 25px;
  //     line-height: unset;
  //     padding: 2px;
  //     transition: .3s ease-in-out;

  //     &:hover {
  //       rotate: 360deg;
  //       color: yellowgreen;
  //     }
  //   }

  //   button {
  //     color: white;
  //     border: unset;
  //     background: unset;
  //     line-height: unset;
  //   }

  //   &:hover {
  //     height: 45px;
  //     width: 45px;
  //     left: -2px;
  //     top: -2px;

  //   }
  // }

  // .input-type {
  //   width: 100%;
  //   display: flex;
  //   justify-content: start;
  //   background-color: black;


  //   .search-area {
  //     height: 40px;
  //     border: none;
  //     margin-top: 35px;
  //     // border-radius: 20px;
  //     padding: 7px 52px 7px 30px;
  //     margin-left: 40px;
  //     width: 300px;
  //     color: black;
  //     // background-image: url('./images/login.svg');
  //     // background-size: 12px;
  //     // background-position: left;

  //   }

  //   ::placeholder {
  //     color: #C9C9C9;
  //     font-weight: 550;
  //   }
  // }
}


@media only screen and (max-width: 1200px) {
  .goBack {
    left: 90px !important;
  }

  .search-area {
    width: 210px;
  }

  .fromTo {

    left: 29% !important;

  }
}

@media only screen and (max-width: 760px) {
  .goBack {
    left: 80px !important;
  }

  .input-type {

    .search-area {
      width: 170px;
    }
  }

  .fromTo {
    left: 0px !important;
    height: 44px;
    top: 8px;

    .refresh {
      margin: 4px;
    }

    input {
      width: 80%;
      // margin: 1px;
    }
  }
}

@media only screen and (max-width: 320px) {

  .fromTo {
    left: 0px !important;
    height: 43px;

    input {
      width: 80%;
      height: 40px;
    }
  }
}

.profiliIcon {
  display: none !important;
}