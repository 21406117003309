.orariPunes {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 75%;


    .orariPunesStatic {
        position: fixed;
        top: 18%;
        bottom: 0;
        right: 0px;
        height: auto;
        overflow: auto;
        margin: 0 auto;

        &::-webkit-scrollbar {
            width: 0;
        }
           .list-item-head-new {
        background-color: #FBD74D;
        color: black;
        font-weight: 700;
        position: sticky;
        top: -1px;
        z-index: 9;

    }

    .list-item {
        padding: 10px 0;
        margin-left: 10px;
        background-color: white;

        &:hover {
            filter: drop-shadow(0 0 0.45rem gray);
            margin-bottom: 2px;
        }

        .iconCursor {
            color: black;
            font-size: 18px;
            cursor: pointer;
        }

        .tableContentStafiAdmin {
            border-top: none;
            @supports(-webkit-appearance: none) or (-moz-appearance: none) {
                input[type='checkbox'] {
                    --active: #FBD74D;
                    --active-inner: #fff;
                    --focus: 2px rgba(39, 94, 254, .3);
                    --border: #BBC1E1;
                    --border-hover: #FBD74D;
                    --background: #fff;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    height: 21px;
                    outline: none;
                    display: inline-block;
                    vertical-align: top;
                    position: relative;
                    margin: 0;
                    cursor: pointer;
                    border: 1px solid var(--bc, var(--border));
                    background: var(--b, var(--background));
                    transition: background .3s, border-color .3s, box-shadow .2s;

                    &:after {
                        content: '';
                        display: block;
                        left: 0;
                        top: 0;
                        position: absolute;
                        transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
                    }

                    &:checked {
                        --b: var(--active);
                        --bc: var(--active);
                        --d-o: .3s;
                        --d-t: .6s;
                        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
                    }

                    &:hover {
                        &:not(:checked) {
                            &:not(:disabled) {
                                --bc: var(--border-hover);
                            }
                        }
                    }

                    &:focus {
                        box-shadow: 0 0 0 var(--focus);
                    }

                    &:not(.switch) {
                        width: 21px;

                        &:after {
                            opacity: var(--o, 0);
                        }

                        &:checked {
                            --o: 1;
                        }
                    }

                }

                input[type='checkbox'] {
                    &:not(.switch) {
                        border-radius: 7px;

                        &:after {
                            width: 5px;
                            height: 9px;
                            border: 2px solid var(--active-inner);
                            border-top: 0;
                            border-left: 0;
                            left: 7px;
                            top: 4px;
                            transform: rotate(var(--r, 20deg));
                        }

                        &:checked {
                            --r: 43deg;
                        }
                    }

                    &.switch {
                        width: 38px;
                        border-radius: 11px;
                        padding: 10px 17px;
                        margin: 0 auto;

                        &:after {
                            left: 2px;
                            top: 2px;
                            border-radius: 50%;
                            width: 15px;
                            height: 15px;
                            background: var(--ab, var(--border));
                            transform: translateX(var(--x, 0));
                        }

                        &:checked {
                            --ab: var(--active-inner);
                            --x: 17px;
                        }

                        &:disabled {
                            &:not(:checked) {
                                &:after {
                                    opacity: .6;
                                }
                            }
                        }
                    }
                }

            }
        }

        .downloadIcon {
            text-decoration: none;
            color: black;

        }
    }

    .listimi {
        margin-top: 20px;
        margin-left: 30px;
        margin-bottom: 50px;
        text-align: left;

        .list-item-head {
            background-color: #FBD74D;
            color: white;
            font-weight: 700;
            padding: 10px 0;
            position: fixed;
            left: 22%;
            right: 2%;
            // bottom: 4%;
            top: calc(1vw + 25%);



        }


    }

        .orariPunes-Form {
            display: block;
            justify-content: center;
            align-items: center;
            border-radius: 0px 0px 12px 12px;
            margin-top: 52px;
            margin-right: 164px;
            // width: 56%;
            margin: 0 auto;

      

     

                form{

                    .input-design {
                        .teksti {
                            width: 95%;
                        }
        
                        .form-control {
                            background: transparent;
                            // height: 50px;
                            padding: 3px;
                            color: black;
                            border: 2px #FBD74D solid;
                            border-radius: 5px;
                            // padding-left: 20px;
                            // padding-right: 20px;
                        }
        
                        .text {
                            width: 14.2%;
                            text-align: center;
                        }
        
                        .checkboxForm {
                            width: 14.2%;
                            text-align: center;
                        }
        
                        label {
                            p {
                                color: #7e8092;
                            }
                        }
        
                       
                    }
                }

                &::-webkit-input-placeholder {
                    color: grey;
                }

                &:hover,
                &:focus {
                    background: transparent;
                    outline: none;
                    box-shadow: none;
                    border-color: rgb(238, 198, 124);
                }

            }

            .btn {
                // margin: 20px 0px 12px 0px;
                // box-shadow: none !important;
                // font-size: 15px;
                // text-transform: uppercase;

                &.btnLogin {
                    width: 100%;
                    background-color: #FBD74D;
                    transition: .3s ease-in-out;
                    color: white;
                    font-weight: 600;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    border: 2px #FBD74D solid;
                }
            }

            


        }
    }
