.formEdit {
    align-self: center;
    overflow: auto;
    position: fixed;
    top: 0;
    bottom: 6px;
    right: 0;
    bottom: 50px;
    background-color: white;
    z-index: 99999;
    height: 100vh;
    padding: 15px;
    float: right;
    width: 46%;

    .editModal-Calendar {
        margin-top: 80px;
    }

    // .bg-primary {
    //     background: #ffab1c !important;
    // }

    .heading-section {
        font-size: 27px;
    }

    .editCalendarWraper {
        width: 88%;
        margin: 0 auto;
        margin-top: 10%;

        form {
            display: block;
            margin-top: 35px;
            font-family: Lato;
            margin-bottom: 50px;
            margin: 14px;

            .form-input {
                .email_input {
                    width: 100%;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    border: 2px #FBD74D solid;
                    padding: 10px;

                    &::-webkit-input-placeholder {
                        color: black;
                    }

                    &:hover,
                    &:focus {
                        background: transparent;
                        outline: none;
                        box-shadow: none;
                        border-color: rgb(238, 198, 124);
                    }
                }
            }

            .button_Edit_Calendar {
                width: 100%;
                border-radius: 5px;
                border: 2px #FBD74D solid;
                padding: 10px;
                transition: .3s ease-in-out;
                background-color: #FBD74D;
                color: white;
                font-weight: 600;

                &:hover {
                    width: 100%;
                    color: white;
                }
            }

            .button_delete_Po {
                width: 100%;
                border-radius: 5px;
                border: 2px #FBD74D solid;
                padding: 10px;
                transition: .3s ease-in-out;
                background-color: #FBD74D;
                color: white;
                font-weight: 600;

                &:hover {
                    width: 100%;
                    color: white;
                }
            }

            .button_delete_Jo {
                width: 100%;
                border-radius: 5px;
                border: 2px rgb(216, 0, 0) solid;
                padding: 10px;
                transition: .3s ease-in-out;
                background-color: rgb(216, 0, 0);
                color: white;
                font-weight: 600;

                &:hover {
                    width: 100%;
                    color: white;
                }
            }

            .selectCustomerEditCalendar {
                width: 100%;
                border-radius: 5px;
                border: 2px #FBD74D solid;
                padding: 10px;
                transition: 0.3s ease-in-out;
                color: black;
            }
        }
    }
}

// Delete Modal
.deleteModalAppointment {
    align-self: center;
    margin: 0 auto !important;
    overflow: hidden;
    border-radius: 10px;
    position: fixed;
    width: 21%;
    right: 0;
    left: 0;
    top: 34%;
    background-color: white;
    z-index: 99999;

    h1,
    h2,
    h3,
    h4,
    h5,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5 {
        line-height: 1.5;
        font-weight: 400;
        color: black;
    }

    .deleteModal-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 4%;
    }



    .heading-section {
        font-size: 27px;
    }

    .deleteContent {
        width: 70%;
        margin: 0 auto;
        margin-top: 70px;

        form {
            display: block;
            margin-top: 35px;
            font-family: Lato;
            margin-bottom: 50px;
            margin: 14px;

            .form-input {
                .email_input {
                    width: 100%;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    border: 2px #FBD74D solid;
                    padding: 10px;

                    &::-webkit-input-placeholder {
                        color: black;
                    }

                    &:hover,
                    &:focus {
                        background: transparent;
                        outline: none;
                        box-shadow: none;
                        border-color: rgb(238, 198, 124);
                    }
                }
            }

            .button_delete_Po {
                width: 100%;
                border-radius: 5px;
                border: 2px #FBD74D solid;
                padding: 10px;
                transition: .3s ease-in-out;
                background-color: #FBD74D;
                color: white;
                font-weight: 600;

                &:hover {
                    width: 100%;
                    color: white;
                }
            }

            .button_delete_Jo {
                width: 100%;
                border-radius: 5px;
                border: 2px rgb(216, 0, 0) solid;
                padding: 10px;
                transition: .3s ease-in-out;
                background-color: rgb(216, 0, 0);
                color: white;
                font-weight: 600;

                &:hover {
                    width: 100%;
                    color: white;
                }
            }

            .selectCustomerEditCalendar {
                width: 100%;
                border-radius: 5px;
                border: 2px #FBD74D solid;
                padding: 10px;
                transition: 0.3s ease-in-out;
                color: black;
            }
        }
    }
}

@media only screen and (max-width: 750px) {
    .formEdit {
        left: 0%;
        right: 0%;
        bottom: 0;
        padding: 0px;
        height: auto;
        width: 100%;

        .editCalendarWraper {
            form {
                .butoniDelete {
                    margin-bottom: 4%;
                }

                .button_Edit_Calendar {
                    margin-bottom: 6%;
                }
            }
        }

        //     .editModal-Calendar {
        //         display: flex;
        //         flex-direction: column;
        //         justify-content: center;
        //         height: 180px;

        //         h5 {
        //             text-align: left !important;
        //             width: 85%;
        //             padding: 20px;
        //             margin-top: 20px;
        //         }

        //         .button_delete {
        //             width: 100%;
        //             border-radius: 5px;
        //             border: 2px rgb(216, 0, 0) solid;
        //             padding: 10px;
        //             transition: .3s ease-in-out;
        //             background-color: rgb(216, 0, 0);
        //             color: white;
        //             font-weight: 600;
        //             margin-bottom: 12px;

        //             &:hover {
        //                 width: 100%;
        //                 color: white;
        //             }
        //         }
        //     }
    }
}

@media only screen and (max-width: 1024px) {
    .deleteModalAppointment {
        width: 90%;

        .deleteModal-wrapper {
            .deleteContent {

                .button_delete_Po,
                .button_delete_Jo {
                    margin-bottom: 4%;
                }
            }
        }
    }
}